import { useState, useEffect } from 'react';
import { getProxyURL } from '../../services/page.service';
import { useSearchParams } from 'react-router-dom';

const DemoPage = () => {
    const [htmlData, setHtmlData]= useState('');
    const [searchParams] = useSearchParams();
    const pageURL = searchParams.get("url");

    useEffect(() => {
        getProxyURL(pageURL).then(response => {   
            if(response?.data){  
                setHtmlData(response?.data);
            }            
        })
        .catch(exception => {        
            //if(!exception.response?.data.hasOwnProperty('errors'))
            //   toast.current.show({severity: 'error', summary: 'Error', detail: exception?.response?.data.message, life: 3000});          
        })
    }, []);

    const createMarkup = () => {
        return  {__html: htmlData};
    }

    return (
         <div dangerouslySetInnerHTML={createMarkup()}></div>
    )
}

export default DemoPage;