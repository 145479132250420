import { NavLink } from "react-router-dom";
import SwitchAccount from "../../switch-account/SwitchAccount";
import "./Header.css";

const Header = ({ type , visibleTop, setVisibleTop }) => {
	
	return (
		<div className="header">
			<div className="flex input-bg">
				<div className="flex-1 search-btn"> <input type="text" placeholder="Search"></input> <button> <img src="images/search.png" /></button> </div>
				<div className="w-140px btn-bg"><button> <img src="images/copy.png" /><NavLink to="/createpage">  New Page </NavLink> </button> </div>
				<div className="w-175px btn-bg"><button><img src="images/users.png" />   New Audience</button> </div>
				<div className="w-215px btn-bg" onClick={() => setVisibleTop(true)}> <button> Account Name</button>
					{type === 1 
						? 	<div className="swich-btn cursor-pointer" > <img src="images/swich.png" />
								<span>Switch Account</span>
							</div> 
						:   <div className="swich-btn"> <img src="images/swich.png" />
								<span>Switch Account</span>
							</div>
					}
				</div>
			</div>
			<SwitchAccount {...{ visibleTop, setVisibleTop }}/>
		</div>
	);
};

export default Header;
