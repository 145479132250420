import { NavLink } from "react-router-dom";
import { ContentView } from "../../components/content-view/ContentView";
import './ContentPage.css'

const ContentPage = () => {
    return (
        <div className="content-container">
            <div className="page-header">
                <h1>GEOFLI CONTENT </h1>
            </div>
            <div className="page-tip">
                <div>
                    <img src="images/blube.png" />
                </div>
                <div className="label">
                    <span>Check your Google Analytics top pages, and add them </span>
                    <a href="#">  here</a>
                    <span> to create engaging experiences.</span>
                    <NavLink to="/createpage">  + ADD PAGE</NavLink>
                </div>
                <img src="images/close.svg" className="close-button" />
            </div>
            <ContentView />
        </div>
    )
}

export default ContentPage;