import { useForm } from "react-hook-form";
import { useState,useRef, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import MapView from '../map-view/MapView';
import './AddPageAudience.css';
import { addAudiences,getAudiences } from '../../services/page.service';
import {Toast} from 'primereact/toast';

const View = ({ inputFields,register,activeStep, setActiveStep, setActiveIndex, activeTab, setActiveTab, tabheader, activeRadiusTargeting, setActiveRadiusTargeting, handleSubmit, onSubmit, errors,toast ,responseErrorMsg }) => {
   
    return (
        <>
            {activeStep === 1 &&
                <div className="add-audience-container">
                    <div className='page-header'>
                        <h1>Now let’s add the different audience(s) that you want to target to</h1>
                        <h1>by choosing any of the methods below:</h1>
                    </div>
                    <div className='page-body'>
                        <div className='item'>
                            <div className='item-img'><img src="./images/location-library.png" /></div>
                            <div className='item-title'>Location Library</div>
                            <div className='item-description'>Choose from a selection of pre-created regions.</div>
                        </div>
                        <div className='item' onClick={() => setActiveStep(2)}>
                            <div className='item-img'><img src="./images/radius-targetting.png" /></div>
                            <div className='item-title'>Radius Targeting</div>
                            <div className='item-description'>Select the area around a zipcode or city</div>
                        </div>
                        <div className='item'>
                            <div className='item-img'><img src="./images/custom-drawing.png" /></div>
                            <div className='item-title'>Custom Drawing</div>
                            <div className='item-description'>Draw the exact region you want to target.</div>
                        </div>
                        <div className='item'>
                            <div className='item-img'><img src="./images/custom-experience-url.png" /></div>
                            <div className='item-title'>Custom Experience URL</div>
                            <div className='item-description'>Personalize content using a URL parameter like a UTM code.</div>
                        </div>
                        <div className='item'>
                            <div className='item-img'><img src="./images/ip-range.png" /></div>
                            <div className='item-title'>IP Range</div>
                            <div className='item-description'>Add the exact set of IP addresses you'd like to target.</div>
                        </div>
                    </div>
                    <div className='page-footer'>
                        <button onClick={() => setActiveIndex(0)}>&lt; Back</button>
                    </div>
                </div>
            }
            <form onSubmit={handleSubmit(onSubmit)}>
            {activeStep === 2 &&
                <div className="add-audience-container">
                    <div className='page-header'>
                        {activeRadiusTargeting === 2 &&
                            <h1>Add a zipcode or city name and desired radius</h1>
                        }
                        {activeRadiusTargeting === 1 &&
                            <h1>Do you want to add more target audience(s)?</h1>
                        }
                        {/* <span className='view-tutorial'><img src="images/youtube.png" />view tutorial</span> */}
                    </div>
                    <div className='page-footer-2'>
                        {activeRadiusTargeting === 2 &&
                            <button onClick={() => setActiveRadiusTargeting(1)}>&lt; Back</button>
                        }
                        {activeRadiusTargeting === 1 &&
                            <>
                                <button onClick={() => setActiveStep(1)}>&lt; Back</button>
                                <button className='btn-continue' onClick={() => setActiveIndex(2)}>CONTINUE</button>
                            </>
                        }
                    </div>
                    <div className='page-body-2'>
                        <div className="tab-view">
                            <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                                <TabPanel header={tabheader("Location Libray")}>
                                    Content I
                                </TabPanel>
                                <TabPanel header={tabheader("Radius Targeting")}>
                                    {activeRadiusTargeting === 2 &&
                                        <div className='flex'>
                                            <div className='flex-1'>
                                                <div className='model-form-row mt-4 ml-4 relative items-center'>
                                                    <label>City<i>*</i></label>
                                                    <input name="city"  type="text" placeholder='e.g. Boston, MA or 02115'  {...register('city', { required: true })} 
                                                    className= {(errors.city?.type === 'required' || responseErrorMsg?.city?.length>0)? "flex-1 border-2 border-rose-600" : "flex-1"} />
                                                    
                                                    {(errors.city?.type === 'required' || responseErrorMsg?.city?.length>0) ? 
                                                     (<i className="error-image tooltip"><span className="tooltiptext">
                                                     {responseErrorMsg?.city?.length>0 ? responseErrorMsg?.city : "This field is required."}</span></i> ) :""}
                                                </div>
                                                <div className='model-form-row ml-4 items-baseline'>
                                                    <label>Radius<i>*</i></label>
                                                    <select name="radius" className='flex-1' {...register('radius', { required: true })}>
                                                        <option value="5">5 miles</option>
                                                        <option value="10">10 miles</option>
                                                        <option value="25">25 miles</option>
                                                        <option value="50">50 miles</option>
                                                        <option value="100">100 miles</option>
                                                    </select>                                                   
                                                </div>
                                                <div className='model-form-row ml-4'>
                                                    <button type="submit" className='save-audience' >SAVE AUDIENCE</button>
                                                </div></div>
                                            <div className='flex-1'>
                                                <MapView />
                                            </div>
                                        </div>
                                    }
                                    {activeRadiusTargeting === 1 &&
                                        <div className='flex'>
                                            <div className='flex-1'>
                                                <div className='success-message'>Audience added successfully</div>
                                                <div className='flex mt-5 flex-wrap'>
                                               
                                                {inputFields.map((x, i) => {
                                                    return(
                                                        <div className='item'>
                                                        <div className='item-title'>{x.city}</div>
                                                        <div className='item-img'><img src="./images/radius-targetting.png" /></div>
                                                        <div className='item-description'>
                                                            <img src="./images/placeholder-copy.png" />
                                                            <span> {x.radius} Radius</span>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                                    <div className='item item-add' onClick={() => setActiveRadiusTargeting(2)}>
                                                        <div className='text-[30px]'>+</div>
                                                        <div>ADD AUDIENCE</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </TabPanel>
                                <TabPanel header={tabheader("Custom Drawing")}>
                                    Content III
                                </TabPanel>
                                <TabPanel header={tabheader("Custom Experience URL")}>
                                    Content IV
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                    <div className='page-footer-2'>
                        {activeRadiusTargeting === 2 &&
                            <button onClick={() => setActiveRadiusTargeting(1)}>&lt; Back</button>
                        }
                        {activeRadiusTargeting === 1 &&
                            <>
                                <button onClick={() => setActiveStep(1)}>&lt; Back</button>
                                <button className='btn-continue' onClick={() => setActiveIndex(2)}>CONTINUE</button>
                            </>
                        }
                    </div>
                </div>
            }
            </form>
            <Toast ref={toast}></Toast>
        </>
    )
}

const AddPageAudience = ({ setActiveIndex }) => {
    const toast = useRef(null);
    const [responseErrorMsg, setResponseErrorMsg] = useState([]);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [inputFields, setData] = useState([]);
    const [activeTab, setActiveTab] = useState(1);
    const [activeStep, setActiveStep] = useState(1);
    const [refresh, setRefresh] = useState(false);
    const [activeRadiusTargeting, setActiveRadiusTargeting] = useState(1);

   
    const tabheader = (title) => {
        return (
            <div>
                {title}
            </div>
        )
    }

    const getTargetProps = (value) => {
        let propsObj = { city: '', state: '', radius: '' };
        if(value){
            let props = value?.split(", ");
            let props2 = props[1]?.split(" - ");
            propsObj.city = props[0];
            propsObj.state = props2[0];
            propsObj.radius = props2[1];
        }
        return propsObj;
    }

    const onSubmit = (params) => {        
        let payload = {
            "active":1,
            "target_type": "RADIUS",
            "lat": "23",
            "lng": "23",
            "city": params.city,
            "state" : "Louisiana",
            "radius": params.radius
        }

        addAudiences(payload).then(response => {            
            if(response?.data){
                setActiveRadiusTargeting(1);
                setRefresh(!refresh);
            }
        })
        .catch(exception => {
            setResponseErrorMsg(exception.response.data.errors);
            if(!exception.response.data.hasOwnProperty('errors'))
                toast.current.show({severity: 'error', summary: 'Error', detail: exception?.response?.data.message, life: 3000});
        })
    }

    useEffect(() => {
        getAudiences().then(response=>{
            let responseData = [];
            if(response?.data)
            {
                for (let i = 0; i < response.data.data.length; i++) {
                    let props = getTargetProps(response.data.data[i]?.name);
                    responseData.push({ city: props?.city, radius: props?.radius});         
                }
                setData(responseData);
            }
         })
    }, [refresh]);

    return <View {...{inputFields, register,activeStep, setActiveStep, setActiveIndex, activeTab, setActiveTab, tabheader, activeRadiusTargeting, setActiveRadiusTargeting, handleSubmit, onSubmit, errors,toast ,responseErrorMsg }} />
}

export default AddPageAudience;


                    
            