import { useState } from 'react';
import BillingNotSubscribed from '../billing-not-subscribed/BillingNotSubscribed';
import BillingSubscribed from '../billing-subscribed/BillingSubscribed';
import './Billing.css';

const View = ({ isSubscribed, toggle }) => {
    return (
        <>
            <button onClick={toggle}>toggle</button>
            {isSubscribed
                ? <BillingSubscribed />
                : <BillingNotSubscribed />
            }
        </>
    )
}

const Billing = () => {
    const [isSubscribed, setIsSubscribed] = useState(true);

    const toggle = () => {
        setIsSubscribed(!isSubscribed);
    }
    return <View {...{ isSubscribed, toggle }} />
}

export default Billing;