import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { addPage } from '../../services/page.service';
import './AddPage.css';
import {Toast} from 'primereact/toast';
import {useRef,useState} from 'react';

const View = ({ register, handleSubmit, onSubmit, errors, confirm,toast,responseErrorMsg }) => {
    return (
        <div className="login-signup-container">
              <div className="page-header">
                    <h1>Let's start by adding your first page. <img src="./images/blube.png" onClick={confirm} /></h1>
                </div>
                <div>
                    <div>
                        <div className="input-indent">
                            <form onSubmit={handleSubmit(onSubmit)} className="max-w-3xl mx-auto">
                            <label className="inline-block w-1/6">Page URL<i>*</i></label>
                                <div className="form-input">                                    
                                    <input
                                        type="text"
                                        placeholder="Example: http://yourdomain.com/store"
                                        name="page_url"
                                        {...register('page_url', { required: true })}
                                        className= {(errors.page_url?.type === 'required' || responseErrorMsg?.path?.length>0) ? "input border-2 border-rose-600" : "input"}
                                    />
                                     {(errors.page_url?.type === 'required' || responseErrorMsg?.path?.length>0) ? 
                                              (<i className="error-image tooltip"><span className="tooltiptext">
                                                {responseErrorMsg?.path?.length>0 ? responseErrorMsg?.path : "Oops! You didn't type your page URL."}</span></i> ) :""}
                                </div>
                                <label htmlFor="page_name" className="inline-block w-1/6">Page Name<i>*</i></label>
                                <div className="form-input">                                   
                                    <input
                                        type="text"
                                        placeholder="Example: Store"
                                        name="page_name"
                                        {...register('page_name', { required: true })}
                                        className= {(errors.page_name?.type === 'required' || responseErrorMsg?.name?.length>0) ? "input border-2 border-rose-600" : "input"}
                                    />
                                    {(errors.page_name?.type === 'required' || responseErrorMsg?.name?.length>0) ? 
                                              (<i className="error-image tooltip"><span className="tooltiptextnext">
                                                {responseErrorMsg?.name?.length>0 ? responseErrorMsg?.name : "This field is required."}</span></i> ) :""}
                                </div>
                                <div className="form-input ">
                                    <label className="inline-block w-1/6">Goal<i>*</i></label>
                                    <select name="goal"
                                        className= {(errors.goal?.type === 'required' || responseErrorMsg?.goal?.length>0) ? "border-2 border-rose-600" : ""}
                                        {...register('goal', { required: true })}>
                                        <option value="CONTENT">Personalize Content</option>
                                        <option value="REDIRECT">Redirect Visitors</option>
                                    </select>
                                    {(errors.goal?.type === 'required' || responseErrorMsg?.goal?.length>0) ? 
                                              (<i className="error-image tooltip"><span className="tooltiptext">
                                                {responseErrorMsg.goal?.length>0 ? responseErrorMsg.goal : "This field is required."}</span></i> ) :""}
                                    
                                </div>
                                <div className="form-input">
                                    <label className="inline-block w-1/6"></label>
                                    <button type="submit" className="add_start" >START</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            <ConfirmDialog />   
            <Toast ref={toast}></Toast>         
        </div>
    )
}

const AddPage = ({ setActiveIndex, setActiveContent, setPageURL, setPageId }) => {
    const toast = useRef(null); 
    const [responseErrorMsg, setResponseErrorMsg] = useState([]);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (params) => {
       
        let payload = {
            "name": params.page_name,
            "path": params.page_url,
            "goal": params.goal        
        }

        addPage(payload).then(response => {          
            if(response?.data){  
                setPageId(response?.data?.id);           
               setActiveIndex(1);
               setPageURL(params.page_url);
                if (params.goal === "REDIRECT") {
                    setActiveContent(1);
                }
                else {
                    setActiveContent(0);
                }

               
            }            
        })
        .catch(exception => {            
            setResponseErrorMsg(exception.response.data.errors);
            if(!exception.response.data.hasOwnProperty('errors'))
                toast.current.show({severity: 'error', summary: 'Error', detail: exception?.response?.data.message, life: 3000});
        })
    }

    const accept = () => { }
    const reject = () => { }

    const confirm = () => {
        confirmDialog({
            message: confirmDialogMessageTemplate(),
            header: confirmDialogHeaderTemplate(),
            accept,
            reject,
            acceptClassName: 'acceptbutton',
            rejectClassName: 'rejectbutton',
            className: 'tip-modal-dialog'
        });
    };

    const confirmDialogMessageTemplate = () => {
        return (
            <div className='tip-model-info'>
                <div>There are three ways to choose which pages to personalize:</div>
                <div>1. Use Google Analytics to determine which pages receive the most traffic on your website.</div>
                <div>1. 2. Determine a conversion funnel on your website
                    Example: Home page &gt; Informational Landing Page &gt; Shop Products Page</div>
                <div>3. Identify the pages you’ll primarily drive traffic to through your ongoing digital marketing campaigns.</div>

                <a>READ MORE</a>
            </div>
        )
    }

    const confirmDialogHeaderTemplate = () => {
        return (
            <div className='tip-model-header'>
                <img src="./images/blube.png" />
            </div>
        )
    }
    return <View {...{ register, handleSubmit, onSubmit, errors, confirm,toast,responseErrorMsg }} />
}

export default AddPage;