import React, { useState, useRef } from 'react';
import AddPage from "../../components/addPage/AddPage";
import AddPageAudience from "../../components/add-page-audience/AddPageAudience";
import AddPageContent from "../../components/add-page-content/AddPageContent";
import AddPageLaunch from '../../components/add-page-launch/AddPageLaunch';
import Steps , { Step } from "rc-steps";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import './CreatePage.css';

// comment
const View = ({ activeIndex, setActiveIndex, items, activeContent, setActiveContent, pageURL, setPageURL, pageId, setPageId }) => {
      
    const  step1Icon = () => {
        return(
            <img width="50%" src={activeIndex === 0 ? 'images/copy-green.png': 'images/right2.png'}/>
        );
    };
    const  step2Icon = () => {
        return(
            <img width="50%" src={activeIndex === 1 
                                    ? 'images/location-green.png'
                                    : activeIndex < 1 
                                    ? 'images/location-grey.png' : 'images/right2.png'}/>
        );
    }
    const  step3Icon = () => {
        return(
            <img width="50%" src={activeIndex === 2 
                                    ? 'images/pen-green.png'
                                    : activeIndex < 2 
                                    ? 'images/pen-grey.png' : 'images/right2.png'}/>
        );
    }
    const  step4Icon = () => {
        return(
            <img width="50%" src={activeIndex === 3 
                                    ? 'images/launch-green.png'
                                    : activeIndex < 3 
                                    ? 'images/launch-grey.png' : 'images/right2.png'}/>
        );
    }
     
    return (
        <div>
            
            <div className="steps-demo">
                <div className="card">
                    <Steps current={activeIndex} size="small" direction="horizontal" labelPlacement="vertical" onChange={(e) => {setActiveIndex(e)}}>
                        <Steps.Step title="PAGE" icon={step1Icon()} disabled={true} className={activeIndex === 0 ? 'active' : ''}/>
                        <Steps.Step title="AUDIENCE" icon={step2Icon()} disabled={true} className={activeIndex === 1 ? 'active' : ''}/>
                        <Steps.Step title="CONTENT" icon={step3Icon()} disabled={true} className={activeIndex === 2 ? 'active' : ''}/>
                        <Steps.Step title="LAUNCH" icon={step4Icon()} disabled={true} className={activeIndex === 3 ? 'active' : ''}/>
                    </Steps>
                    {/* <Steps className="steps" model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} /> */}
                </div>
                <div className="view" >
                    {activeIndex === 0 && 
                         <AddPage {...{setActiveIndex, setActiveContent, setPageURL, setPageId }} />
                    }
                    {activeIndex === 1 && 
                        <AddPageAudience {...{setActiveIndex}}  />
                    }
                    {activeIndex === 2 && 
                        <AddPageContent {...{setActiveIndex, activeContent, pageURL, pageId}} />
                    }
                    {activeIndex === 3 &&
                        <AddPageLaunch {...{setActiveIndex}} />
                    }
                </div>
                {/* <div className='text-center'>
                    <span className='view-tutorial'><img src="images/youtube.png" />view tutorial</span>
                </div>                 */}
            </div> 
        </div>
    )
}

const CreatePage = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeContent, setActiveContent] = useState(0);
    const [pageURL, setPageURL] = useState('');
    const [pageId, setPageId] = useState(0);
    //const toast = useRef(null);
    const items = [
        {
            id: '1',
            label: 'PAGE',
            command: (event) => {
                //toast.current.show({ severity: 'info', summary: 'PAGE', detail: event.item.label });
            }
        },
        {
            id: '2',
            label: 'AUDIENCE',
            command: (event) => {
                //toast.current.show({ severity: 'info', summary: 'AUDIENCE', detail: event.item.label });
            }
        },
        {
            id: '3',
            label: 'CONTENT',
            command: (event) => {
                //toast.current.show({ severity: 'info', summary: 'CONTENT', detail: event.item.label });
                setActiveIndex(2);
            }
        },
        {
            id: '4',
            label: 'LAUNCH',
            command: (event) => {
                //toast.current.show({ severity: 'info', summary: 'LAUNCH', detail: event.item.label });
            }
        }
    ];
    return <View  {...{ activeIndex, setActiveIndex, items, activeContent, setActiveContent, pageURL, setPageURL, pageId, setPageId }} />
}

export default CreatePage;