import { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { TabView, TabPanel } from 'primereact/tabview';
import MapView from '../map-view/MapView';
import './AudiencesEdit.css';
import { useForm } from 'react-hook-form';
import { getAudienceById, updateAudiences } from '../../services/page.service';
import { useEffect } from 'react';
import { Toast } from 'primereact/toast';

const View = ({ isOpen, setIsOpen, header, activeIndex, setActiveIndex, tabheader, register, handleSubmit, onSubmit, errors, toast ,responseErrorMsg }) => {
    return(
        <>
            <Dialog header={header} visible={isOpen} onHide={() => setIsOpen(false)} breakpoints={{'960px': '75vw'}} style={{width: '50vw'}} className="modal-dialog">
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header={tabheader("Location Libray")}>
                    Content I
                </TabPanel>
                <TabPanel header={tabheader("Radius Targeting")}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex'>
                            <div className='flex-1'>
                                <div className='model-form-row'>
                                    <label>City<i>*</i></label>
                                    <input name='city' type="text" placeholder='e.g. Boston, MA or 02115' {...register('city', { required: true })} 
                                                        className= {(errors.city?.type === 'required' || responseErrorMsg?.city?.length>0)? "flex-1 border-2 border-rose-600" : "flex-1"} /> 

                                    {(errors.city?.type === 'required' || responseErrorMsg?.city?.length>0) ? 
                                    (<i className="error-image tooltip"><span className="tooltiptext">
                                    {responseErrorMsg?.city?.length>0 ? responseErrorMsg?.city : "This field is required."}</span></i> ) :""}
                                </div>
                                <div className='model-form-row'>
                                    <label>Radius<i>*</i></label>
                                    <select name="radius" className='flex-1' {...register('radius', { required: true })}>
                                        <option value="5">5 miles</option>
                                        <option value="10">10 miles</option>
                                        <option value="25">25 miles</option>
                                        <option value="50">50 miles</option>
                                        <option value="100">100 miles</option>
                                    </select>
                                </div>
                                <div className='model-form-row'>
                            
                                    <button className='save-audience'>SAVE AUDIENCE</button>
                                
                            </div></div>
                            <div className='flex-1'>
                                <MapView />
                            </div>
                        </div>
                    </form>
                </TabPanel>
                <TabPanel header={tabheader("Custom Drawing")}>
                    Content III
                </TabPanel>
                <TabPanel header={tabheader("Custom Experience URL")}>
                    Content IV
                </TabPanel>
            </TabView>
        </Dialog>
         <Toast ref={toast} />
        </>
    )
}


const AudiencesEdit = ({ isOpen, setIsOpen, selectedAudience, refresh, setRefresh }) => {
    const toast = useRef(null);
    const [responseErrorMsg, setResponseErrorMsg] = useState([]);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [activeIndex, setActiveIndex] = useState(1);
    const header = () => {
        return(
            <div className='text-center'>
                <span>Edit your audience below</span>
                <span className='view-tutorial'><img src="images/youtube.png"/>view tutorial</span>
            </div>
        )
    }

    const onSubmit = (params) => {       
        let payload = {
            id: selectedAudience,
            city: params.city,
            state : "Louisiana",
            radius: params.radius,
            active: true,
            target_type: "RADIUS",
        }

        updateAudiences(payload).then(response => {            
            if(response?.data){
                toast.current.show({severity:'success', summary: 'Success', detail:response?.data, life: 3000});
                setIsOpen(false);
                setRefresh(!refresh)
            }
        })
        .catch(exception => {
            setResponseErrorMsg(exception.response?.data?.errors);
            if(!exception.response.data.hasOwnProperty('errors'))
                toast.current.show({severity: 'error', summary: 'Error', detail: exception?.response?.data.message, life: 3000});
        })
    }

    useEffect(() => {
        getAudienceById(selectedAudience).then(response=>{
            if(response?.data) {
                let name = response?.data.data?.name?.split(' - ');
                setValue('city', name[0]);
                setValue('radius', name[1].split(' ')[0]);
            }
         })
    }, [selectedAudience]);

    const tabheader = (title) => {
        return(
            <div>
                {title}
            </div>
        )
    }

    return <View {...{isOpen, setIsOpen, header, activeIndex, setActiveIndex, tabheader, register, handleSubmit, onSubmit, errors, toast ,responseErrorMsg }}/>
}

export default AudiencesEdit;