import React, { useState,useEffect  } from 'react';
import { DataView } from 'primereact/dataview';
import PagesPageBox from '../pages-page-box/PagesPageBox';
import {getPage} from "../../services/page.service";
import './PagesView.css';

const View = ({ data, layout, header, itemTemplate, setLayout }) => {
    return (
        <>
            <div className='view-header'>
                <div className="flex-1 flex">
                    <div className="dim">
                        <span className='small-font'>Total Pages</span>
                        <span>6</span>
                    </div>
                    <div className="highlight">
                        <span className='small-font'>Total Visits</span><br></br>
                        <span>140.3k</span>
                    </div>
                </div>
                <div className="flex-1 flex justify-end">
                    {layout === 'list'
                        ? <div className='gride-tab-menu'>
                            <div className='gride-tab-menu-active' onClick={() => setLayout('list')}>
                                <img src="images/group-9.png" />
                                <span>LIST VIEW</span>
                            </div>
                            <div onClick={() => setLayout('grid')}>
                                <img src="images/grid-view.png" />
                                <span>GRID VIEW</span>
                            </div>
                        </div>
                        : <div className='gride-tab-menu'>
                            <div onClick={() => setLayout('list')}>
                                <img src="images/group-9.png" />
                                <span>LIST VIEW</span>
                            </div>
                            <div className='gride-tab-menu-active' onClick={() => setLayout('grid')}>
                                <img src="images/grid-view.png" />
                                <span>GRID VIEW</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="dataview">
                <div className="dataview-card">
                    <DataView value={data} layout={layout} header={header}
                        itemTemplate={itemTemplate} paginator rows={6} className="data-view" />
                </div>
            </div>
        </>
    )
}

const ListItem = ({ data }) => {
    return (
        <div className='pages-list-item'>
            <div className='grid'>
                <span className='text-left w-fit border-none text-[#179ab7] text-[18px] font-normal'>{data.name}</span>
                <span className='text-left w-fit border-none text-[#828282] text-[16px] font-thin'>{data.domain}</span>
            </div>
            <div>{data.actualizations}</div>
            <div>{data.clicks}</div>
            <div>{data.sessions}</div>
            <div>
                <button className='text-left w-fit border-none text-[#179ab7] text-[16px] font-normal'>+ ADD MORE CONTENT</button>
                <button className='text-left w-fit border-none text-[#179ab7] text-[16px] font-normal'><span>||</span> PAUSE</button>
            </div>
        </div>
    )
}

const GridItem = ({ data }) => {
    return (
        <div className="grid-view-container">
            <PagesPageBox item={data} />
        </div>
    )
}

const HeaderTemplate = ({ columns }) => {
    return (
        <div className="pages-list-item-header">
            {columns.map((item) => (
                <div key={item.field}>
                    {item.icon && item.iconType === 'search' && <img className='pr-[5px]' src="images/search-copy.png" />}
                    {item.icon && item.iconType !== 'search' && <img className='pr-[5px]' src="images/group-14-copy.png" />}
                    {item.header}
                </div>
            ))}
            <div key={'action'}>Action</div>
        </div>
    )
}

const PagesView = () => {
    const [layout, setLayout] = useState('list');
    const [data, setData] = useState([]);   
    
    const columns = [
        { field: 'name', header: 'Page Name', icon: true, iconType: 'search' },
        { field: 'actualizations', header: 'Actualizations', icon: true },
        { field: 'clicks', header: 'Clicks', icon: true },
        { field: 'session', header: 'Session', icon: true }
    ];

    useEffect(() => {        
        getPage().then(response => {       
            if(response?.data){ 
                let responseData = [];
                for (let i = 0; i < response.data.data.length; i++) {
                    responseData.push({ name: response.data.data[i].name, domain: response.data.data[i].page_url, actualizations: '59,620k', clicks: '120', sessions: '86,210k', value: '140,360k', type: '1' });         
                }
                setData(responseData);           
            }      
        })
        .catch(exception => {
    
        })
      },[])
    

   

    const itemTemplate = (data, layout) => {
        if (!data) {
            return;
        }
        return layout === 'list' ? <ListItem data={data} /> : <GridItem data={data} />;
    }

    const header = renderHeader(layout, columns);

    return <View {...{ data, layout, header, itemTemplate, setLayout }} />
}

const renderHeader = (layout, columns) => {
    return (
        <>
            {layout === 'list' &&
                <HeaderTemplate columns={columns} />
            }
        </>
    )
}

export default PagesView;