import './GeotargettingInsights.css';

const View = ({ data }) => {
    return (
        <div>
            <div className='geotargetting'>Geotargetting Insights</div>
            <div>
                {data.map((item, i) => (
                    <div className='general-item' key={`insight-${i}`}>
                        <div className='general-item-img'>
                            <img src={`images/${item.image}`} />
                        </div>
                        <div className='general-item-info'>
                            <div className='general-item-header'>{item.title}</div>
                            <div>{item.details}</div>
                        </div>
                    </div>
                ))}

                <div className='blue-btn'>
                    <button>view more insights</button>
                </div>
            </div>
        </div>
    )
}

const GeotargettingInsights = () => {

    const data = [
        {
            title: 'How to Use GeoFli for Email Marketing',
            image: 'insights1.png',
            details: `As digital marketers, we can only try to stay on top of the tools available for our use. New apps and 
                    services are being created by the minute; for mobile apps alone, TechCrunch projects 352 billion by
                    2021. With all this noise, it can be difficult to prioritize your marketing channels correctly. So we
                    recommend covering your bases and researching the classics. One of our favorite classics here at GeoFli?
                    Email marketing.`
        },
        {
            title: 'How to Use GeoFli to Improve Your Content Marketing',
            image: 'insights2.png',
            details: `Writing content for your website is more than just providing your users with valuable information. It also
                    means optimizing your site's search engine performance with strategic, well-picked keywords, ensuring
                    your site is visible to the people most likely to convert. What search terms do you want your site to
                    appear first on the results page for? What services do you want to highlight with your SEO strategy? And
                    how does user location affect your messaging?`
        },
        {
            title: 'How Does Geotargeting Work?',
            image: 'insights3.png',
            details: `The world of geotargeting is filled with technical jargon that just doesn't quite resonate with the
                    average Joe - when similar-sounding words like geotargeting, geofencing and geo redirecting all haye
                    their own distinct meanings that seem to differ from person to person, the terminology can get a little
                    confusing.`
        }
    ]

    return <View {...{ data }} />
}

export default GeotargettingInsights;