import './MapView.css';

const View = () => {
    return(
        <div className='mapViewContainer'>
            <img src="images/map.png" />
        </div>
    )
}

const MapView = () =>{
    return <View />;
}

export default MapView;