import { useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Calendar } from 'primereact/calendar';
import AudienceSegment from '../../components/audience-segment/AudienceSegment';
import MapView from '../../components/map-view/MapView';
import PageList from '../../components/page-list/PageList';
import './AnalyticsPage.css';

const View = ({ activeTab, setActiveTab, header1Template, header2Template, dateRange, setDateRange, pages, audiences, contents, pagesColumns, audienceColumn, contentColumns }) => {
    return (
        <div className='analytics-container'>
            <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                <TabPanel headerTemplate={header1Template} className='panel-container'>
                    <h1>Peter, you’ve done a <span>great job</span> this month!</h1>
                    <h3>Here are a couple of suggestions to keep improving:</h3>
                    <div className='card-view'>
                        <div className='card'>
                            <div className='card-header'>fun fact</div>
                            <div className='card-body'>
                                <img src="images/goal-1.png" />
                                <span>Mount Everest is the tallest mountain on Earth, the highest point on Earth. But when you remember that Earth is slightly oval-shaped, things get interesting.
                                    <a href="#">Learn More</a>
                                </span>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='card-header'>fun fact</div>
                            <div className='card-body'>
                                <img src="images/stat.png" />
                                <span>The lates search trends in you area are:
                                    <br />Topic One<br />
                                    Topic Two.<br />
                                    You can customize content based on these topic
                                    <a href="#">here</a>.
                                </span>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='card-header'>fun fact</div>
                            <div className='card-body'>
                                <img src="images/chart.png" />
                                <span>Check your Google Analytics top pages, and add them
                                    <a href="#">here</a> to create engaging experiences.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='containermap-header'>
                        <div>
                            <Calendar value={dateRange} dateFormat="M dd, yy - DD" onChange={(e) => setDateRange(e.value)} showIcon="true" icon="pi pi-chevron-down" readOnlyInput />
                        </div>
                        <div className='title'>Overview</div>
                        <div className='buttons'>
                            <button>Download <img src='images/download.png' /></button>
                            <button>Send <img src='images/mail-send.png' /></button>
                        </div>
                    </div>
                    <div className='containermap'>
                        <AudienceSegment />
                        <MapView />
                    </div>
                    <div className="cardcontainer">
                        <ul className="flex">
                            <li className="flex-1">
                                <div className="cardheader"> Time-On-Site</div>
                                <div className="cardtime">
                                    140 secs
                                </div>
                                <div className="cardinfo"> 2% Increase</div>
                                <div className="cardinfoimg"> <img src="images/card-info.png" /></div>
                            </li>

                            <li className="flex-1">
                                <div className="cardheader"> Pages Per-Session</div>
                                <div className="cardtime">
                                    4 pages
                                </div>
                                <div className="cardinfo"> 10% Increase</div>
                                <div className="cardinfoimg"> <img src="images/card-info.png" /></div>
                            </li>

                            <li className="flex-1">
                                <div className="cardheader"> Conversion Rate</div>
                                <div className="cardtime">
                                    Great work!
                                </div>
                                <div className="cardinfo"> 22% Increase</div>
                                <div className="cardinfoimg"> <img src="images/card-info.png" /></div>
                            </li>

                            <li className="flex-1">
                                <div className="cardheader"> GeoFli Redirects</div>
                                <div className="cardtime">
                                    102
                                </div>
                                <div className="cardinfo"> 10% Increase</div>
                                <div className="cardinfoimg"> <img src="images/card-info.png" /></div>
                            </li>
                        </ul>
                    </div>
                    <div><h1>GeoFlied Pages</h1></div>
                    <PageList 
                        isHeader={false}
                        isEdit={false}
                        isFooter={false}
                        pages={pages}
                        isRowGroup={true}
                        columns={pagesColumns}
                    />
                    <div><h1>AUDIENCE</h1></div>
                    <PageList 
                        isHeader={false}
                        isEdit={false}
                        isFooter={false}
                        pages={audiences}
                        isRowGroup={false}
                        columns={audienceColumn}
                    />
                    <div><h1>CONTENT</h1></div>
                    <PageList 
                        isHeader={false}
                        isEdit={false}
                        isFooter={false}
                        pages={contents}
                        isRowGroup={false}
                        columns={contentColumns}
                    />
                </TabPanel>
                <TabPanel headerTemplate={header2Template}>

                </TabPanel>
            </TabView>
        </div>
    )
}

const AnalyticsPage = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [dateRange, setDateRange] = useState(null);

    const pagesColumns = [
        { field: 'column1', header: '', sortable: false, icon: false, link: true },
        { field: 'column2', header: 'Actualizations', sortable: true, icon: true, link: false },
        { field: 'column3', header: 'On-Page Clicks', sortable: true, icon: true, link: false },
        { field: 'column4', header: 'Sessions', sortable: true, icon: true, link: false },
        { field: 'column5', header: 'Date Geoflied', sortable: true, icon: true, link: false },
    ];

    const audienceColumn = [
        { field: 'column1', header: 'Audiences', sortable: true, icon: true, link: true },
        { field: 'column2', header: 'Actualizations', sortable: true, icon: true, link: false },
        { field: 'column3', header: 'On-Page Clicks', sortable: true, icon: true, link: false },
        { field: 'column4', header: 'Sessions', sortable: true, icon: true, link: false },
        { field: 'column5', header: 'Date Geoflied', sortable: true, icon: true, link: false },
    ];

    const contentColumns = [
        { field: 'column1', header: 'Change', sortable: true, icon: true, link: true },
        { field: 'column2', header: 'GeoTarget', sortable: true, icon: true, link: false },
        { field: 'column3', header: 'Actualizations', sortable: true, icon: true, link: false },
        { field: 'column4', header: 'On-Page Clicks', sortable: true, icon: true, link: false },
        { field: 'column5', header: 'Sessions', sortable: true, icon: true, link: false },
        { field: 'column6', header: 'Date Geoflied', sortable: true, icon: true, link: false },
    ];

    const pages = [
        { column1: 'Southwest', column2: '9620', column3: '86', column4: '42302', column5: '01/03/2019', column6: 'A-Page Name with words and information' },
        { column1: 'New York', column2: '7280', column3: '30', column4: '12000', column5: '01/03/2019', column6: 'A-Page Name with words and information' },
        { column1: 'LA', column2: '3100', column3: '4', column4: '6000', column5: '01/03/2019', column6: 'A-Page Name with words and information' },
        { column1: 'Southwest', column2: '9620', column3: '86', column4: '42302', column5: '01/03/2019', column6: 'B-Page Name with words and information' },
        { column1: 'New York', column2: '7280', column3: '30', column4: '12000', column5: '01/03/2019', column6: 'B-Page Name with words and information' },
    ];

    const audiences = [
        { column1: 'Southwest', column2: '9620', column3: '86', column4: '42302', column5: '01/03/2019', column6: 'A-Page Name with words and information' },
        { column1: 'New York', column2: '7280', column3: '30', column4: '12000', column5: '01/03/2019', column6: 'A-Page Name with words and information' },
    ];

    const contents = [
        { column1: 'Content for Video', column2: 'Southwest', column3: '9620', column4: '86', column5: '42302', column6: '01/03/2019', column7: 'A-Page Name with words and information' },
        { column1: 'JS for Seal Content', column2: 'New York', column3: '7280', column4: '30', column5: '12000', column6: '01/03/2019', column7: 'A-Page Name with words and information' },
    ];

    const header1Template = (options) => {
        return (
            <div className='tab-header' onClick={options.onClick}>
                <img src="images/location-black.png" />
                <span>GeoFli Analytics</span>
            </div>
        )
    }

    const header2Template = (options) => {
        return (
            <div className='tab-header' onClick={options.onClick}>
                <img src="images/chart-org.png" />
                <span>GeoFli Analytics</span>
            </div>
        )
    }

    return (
        <View {...{ activeTab, setActiveTab, header1Template, header2Template, dateRange, setDateRange, pages, audiences, contents, pagesColumns, audienceColumn, contentColumns }} />
    )
}

export default AnalyticsPage;