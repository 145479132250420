import AccountHistory from '../account-history/AccountHistory';
import './BillingSubscribed.css';

const View = () => {
    return (
        <div>
            <div className='billing-subscribed-header'>Current Subscription</div>
            <div className='billing-list-view'>
                <div className='billing-list-img'><img src="images/subscription-pro.png" /></div>
                <div className='billing-list-info'>
                    You’re currently subscribed on a<span> monthly basis.</span>
                    <br />
                    You account is being charged to the card ending in:<span> 3151</span>
                </div>
                <div className='billing-list-button'>
                    <button>Update Billing Information</button>
                    <button>Change Plan</button>
                </div>
            </div>
            <div className='billing-list-subheader'>
                If you experience any issues or have any questions please contact us at
                <a href="mailto:support@geofli.com"> support@geofli.com</a>
            </div>
            <div>
                <AccountHistory />
            </div>
        </div>
    )
}

const BillingSubscribed = () => {
    return <View />
}

export default BillingSubscribed;