import { Sidebar } from 'primereact/sidebar';
import { useRef, useState, useEffect } from 'react';
import { getUserAccounts, setCurrentAccount } from '../../services/account.service';
import Header from '../layout/header/Header';
import SideBar from '../layout/sideBar/SideBar';
import './SwitchAccount.css';

const SwitchAccount = ({ visibleTop, setVisibleTop }) => {
    const toast = useRef(null);
    const [accounts, setAccounts] = useState([]);
    const userId = localStorage.getItem('user_id');

     useEffect(() => {
        getUserAccounts(userId).then(response => {
            if (response?.data) {
                setAccounts(response?.data?.data);
            }
        })
        .catch(exception => {
            //toast.current.show({severity: 'error', summary: 'Error', detail: exception?.response?.data.message, life: 3000});    
        })
     }, [])

     const onSwitchAccount = (account_id) => {
        if(localStorage.getItem('account_id') != account_id) {

            let payload = {
                userId: userId,
                account_id: account_id
            }

            setCurrentAccount(payload).then(response => {         
                if(response?.data){  
                    localStorage.setItem('account_id', account_id);  
                    window.location.reload(true);
                }            
            })
            .catch(exception => {           
                if(!exception.response.data.hasOwnProperty('errors'))
                    toast.current.show({severity: 'error', summary: 'Error', detail: exception?.response?.data.message, life: 3000});
            })
            //localStorage.setItem('account_id', account_id);  
            //window.location.reload(true);
        }
     }
    
    return(
        <Sidebar visible={visibleTop} fullScreen position="top" onHide={() => setVisibleTop(false)} showCloseIcon={false} blockScroll={true}>
            <div className="flex">
                <div className="w-72">
                    <SideBar />
                </div>
                <div className="flex-1">
                    <div className="switch-account-header">
                    <Header type={2}/>
                    </div>
                    <div className="switch-account-content">
                        <div className="text-center text-white p-8 text-2xl">
                            <span>Switch account by selecting it below:</span>
                            <button type="button" className="float-right" onClick={() => setVisibleTop(false)} aria-label="close"><span className="pi pi-times"></span></button>
                        </div>
                        <div className="grid grid-cols-4 gap-8 p-8">
                            {accounts?.length && accounts.map((item)=> (
                                <div className="bg-white text-black p-12 text-center cursor-pointer relative" key={item.id} onClick={() => onSwitchAccount(item.id)}>{item.name}
                                    {item.id == localStorage.getItem('account_id') &&
                                        <span class="absolute bg-[#ed9630] px-2 py-1 text-xs font-bold -top-0 -right-0 right-[5px] top-[5px]"><img src="images/right.png"></img></span>
                                    }
                                </div>
                            ))}
                            <div className="text-white p-12 text-center border-dashed border-white border-2 cursor-pointer">+ New Account</div>
                        </div>
                    </div>
                </div>
            </div>
        </Sidebar>

            
    )
}

export default SwitchAccount;