import AppRouter from './route/AppRouter';
import axios from 'axios';
import 'primereact/resources/themes/tailwind-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { render } from 'react-dom';

const App = () => {
	return <AppRouter />;
};

const main = async () => {
	render(<App />, document.getElementById('root'));
};

axios.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem('token');
		config.url = 'https://api.geofli.com' + config.url;

		if (token) {
			config.headers['Authorization'] = 'Bearer ' + token;
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	(response) => {
		return response;
	},
	function (error) {
		return Promise.reject(error);
	}
);

main();
