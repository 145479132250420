import './AddPageLaunch.css';

const View = () => {
    return (
        <div className="add-launch-container">
            <div className="view">
                <div className="section-1">
                    <span>GeoFli Page Status </span>
                    <span>Code pending to be added on website</span>
                </div>
                <div className="section-2">To launch your GeoFli Page
                    add the following code on your website page  &lt;head&gt; tag:</div>
                <div className="section-3">&lt;script src='https://app.geofli.com/code/2840/widget.js'&gt;&lt;/script&gt;</div>
                <div className='section-4'>
                    <input type="checkbox" />
                    <span>Auto-publish. Uncheck if you don't want your changes to be publish right away.</span>
                </div>
                <div className="section-5">
                    {/* <span><img src="images/youtube.png" />view tutorial</span> */}
                </div>
                <div className="section-6">
                    <div className='icon'><img src="./images/blube.png" /></div>
                    <div className='label'>
                        <p>You can also <span>email instructions to your IT or web professional</span>.</p>
                        <p>For questions or assistance with setting up your pages, please visit our <span>Support page</span> or give us a call <br></br><span>(406) 214-3809.</span></p>
                    </div>
                </div>
                <div className="section-7">Other options</div>
                <div className="section-8">
                    <div className='box mr-[7px]'>
                        <div className='box-header'>Content Grid</div>
                        <div className='box-body'>View what percentage of your content has been "GeoFlied"</div>
                    </div>
                    <div className='box ml-[7px]'>
                        <div className='box-header'>Bulk Editing Content</div>
                        <div className='box-body'>Edit all of your content for this section at once.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const AddPageLaunch = ({ setActiveIndex }) => {
    return <View />;
}

export default AddPageLaunch;