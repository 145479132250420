import React, { useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import MapView from '../map-view/MapView';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import AudiencesEdit from '../audiences-edit/AudiencesEdit';
import './AudiencesListView.css';
import { deleteAudience, getAudiences } from '../../services/page.service';
import { useEffect } from 'react';


const View = ({ pages, first, rows, paginatorTemplate, onCustomPage, actionBodyTemplate, mapBodyTemplate, audiencesBodyTemplate, actionHeaderTemplate, toast, isOpen, setIsOpen, visible, setVisible, selectedAudience, refresh, setRefresh, confirmDialogMessageTemplate, confirmDialogHeaderTemplate, acceptFunc, rejectFunc }) => {
    return(
        <div className="audience-list-view">
            <DataTable value={pages} responsiveLayout="scroll"
                paginator paginatorTemplate={paginatorTemplate} first={first} rows={rows} onPage={onCustomPage}
                paginatorClassName="justify-content-end" dataKey="id">
                <Column field='' body={mapBodyTemplate} header=''></Column>
                <Column field='name' body={audiencesBodyTemplate}  header={() => actionHeaderTemplate({header: 'AUDIENCES', icon: false})}></Column>
                <Column field='actualizations' header={() => actionHeaderTemplate({header: 'ACTUALIZATIONS', icon: true})} className="textCenter"></Column>
                <Column field='sessions' header={() => actionHeaderTemplate({header: 'SESSIONS', icon: true})} className="textCenter"></Column>
                <Column body={(e) => actionBodyTemplate(setIsOpen, e.target_id)} header={() => actionHeaderTemplate({header: 'ACTIONS', icon: false})}></Column>
            </DataTable>
            <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message={confirmDialogMessageTemplate}
                    header={confirmDialogHeaderTemplate} accept={acceptFunc} reject={rejectFunc} 
                    acceptClassName='acceptbutton' rejectClassName='rejectbutton' className='confirm-modal-dialog' />
            <AudiencesEdit {...{isOpen, setIsOpen, selectedAudience, refresh, setRefresh}}/>
            <Toast ref={toast} />
        </div>
    )
}

const AudiencesListView = () => {
    const toast = useRef(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [isOpen, setIsOpen] = useState(false);
    const [pages, setPages] = useState([]); 
    const [refresh, setRefresh] = useState(false); 
    const [visible, setVisible] = useState(false); 
    const [selectedAudience, setSelectedAudience] = useState(0); 

    useEffect(() => {
        getAudiences().then(response=>{
            if(response?.data) {
                setPages(response?.data?.data);
            }
         })
    }, [refresh]);

    const paginatorTemplate = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            )
        }
    };

    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    }

    const actionHeaderTemplate = (col) => {
        return (
            <div className={col.icon ? 'info-td' : ''}>
                {col.icon && <img src="images/group-14-copy.png" />}
                <span>{col.header}</span>
            </div>
        )
    }

    const onEdit = (id) => {
        setSelectedAudience(id);
        setIsOpen(true);
    }

    const actionBodyTemplate = (setIsOpen, id) => {
        return(
            <span className='edit-delete-btn'>
                <button onClick={() => onEdit(id)}><img src="images/edit-3-copy-4.png"/>Edit</button>
                <button onClick={() => {setVisible(true);setSelectedAudience(id);}}><img src="images/delete.png"/>Delete</button>
            </span>
        )
    }

    const mapBodyTemplate = (rowData) => {
        return(
            <>
                <MapView />
            </>
        )
    }

    const audiencesBodyTemplate = (rowData) => {
        return(
            <>
                <span className='blue-color'>{rowData.name?.split('-')[0]}</span>
            </>
        )
    }
    
    const acceptFunc = () => {
        deleteAudience(selectedAudience).then(response => {         
            if(response?.data){  
                toast.current.show({severity:'success', summary: 'Success', detail:response?.data, life: 3000});
                setVisible(false);
                setRefresh(!refresh);
            }            
        })
        .catch(exception => {           
            if(!exception.response.data.hasOwnProperty('errors'))
                toast.current.show({severity: 'error', summary: 'Error', detail: exception?.response?.data.message, life: 3000});
        })
    }

    const rejectFunc = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const confirmDialogMessageTemplate = () => {
        return(
            <div className='delete-model-info' onClick={() => acceptFunc()}>
                  <div className='delete-model-info-text'> <span>Important: </span>You will lost all data related to this audience</div>

                  <button className='delete-large-btn'><img src="images/red-delete.png" /> DELETE</button>
            </div>
        )
    }

    const confirmDialogHeaderTemplate = () => {
        return(
            <div className='delete-model-header'>
                  <span>Are you sure you want <div>to delete your audience?</div></span>
            </div>
        )
    }

    return <View {...{ pages, first, rows, paginatorTemplate, onCustomPage, actionBodyTemplate, mapBodyTemplate, audiencesBodyTemplate, actionHeaderTemplate, toast, isOpen, setIsOpen, visible, setVisible, selectedAudience, refresh, setRefresh, confirmDialogMessageTemplate, confirmDialogHeaderTemplate, acceptFunc, rejectFunc }} />
}

export default AudiencesListView;