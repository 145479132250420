import { Dialog } from 'primereact/dialog';
import './BillingSubscriptionStart.css';

const View = ({ isOpen, setIsOpen, subscriptionType }) => {
    return (
        <Dialog visible={isOpen} onHide={() => setIsOpen(false)} breakpoints={{ '960px': '75vw' }} style={{ width: '40vw' }} className="starter-modal-dialog starter-popup">
            <div className='starter-popup-img'>
                {subscriptionType === 0 && <img src="images/starter-complete.png" />}
                {subscriptionType === 1 && <img src="images/intermediate-complete.png" />}
                {subscriptionType === 2 && <img src="images/subscription-pro.png" />}
            </div>
            <div className='popup-form'>
                <div>
                    <label>Card Number</label>
                    <input type="text" />
                </div>
                <div>
                    <label>Expiration (MM/YYYYY)</label>
                    <input type="text" />
                </div>
                <div>
                    <label>CVC</label>
                    <input type="text" />
                </div>
            </div>
            <div className='popup-form-footer'>
                <div className='popup-sub-btn'>
                    <button>SUBSCRIBE</button>
                </div>
                <div className='billing-list-sub-head'>
                    If you experience any issues or have any questions<br />
                    please contact us at <a href="mailto:support@geofli.com"> support@geofli.com</a>
                </div>
                <div className='popup-footer-img'>
                    <img src="images/bitmap.png" />
                </div>
            </div>
        </Dialog>
    )
}


const BillingSubscriptionStart = ({ isOpen, setIsOpen, subscriptionType }) => {
    return <View {...{ isOpen, setIsOpen, subscriptionType }} />
}

export default BillingSubscriptionStart;