import axios from 'axios';

const account = localStorage.getItem('account_id');

const getContents = async (payload) => {
	return await axios.get(`/v1/accounts/${account}/pages/${payload.page_id}/content`).then((res) => res);
};

const deleteContent = async (payload) => {
	return await axios.delete(`/v1/accounts/${account}/pages/${payload.page_id}/content/${payload.id}`).then((res) => res);
};

const getContentById = async (payload) => {
	return await axios.get(`/v1/accounts/${account}/pages/${payload.page_id}/content/${payload.id}`).then((res) => res);
};

const updateContent = async (payload) => {
	return await axios.put(`/v1/accounts/${account}/pages/${payload.page_id}/content/${payload.id}`, payload).then((res) => res);
};

export { getContents, deleteContent, getContentById, updateContent };
