import { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import './Content.css';

const View = ({ data, actionBodyTemplate, first, rows, paginatorTemplate, onCustomPage, contentBodyTemplate }) => {
    return (
        <div>
            <div className='content-header'>Content</div>
            <DataTable value={data} responsiveLayout="scroll" className="content"
                paginator paginatorTemplate={paginatorTemplate} first={first} rows={rows} onPage={onCustomPage}
                paginatorClassName="justify-content-end">
                <Column field='page' header='Page'></Column>
                <Column field='target' header='Target'></Column>
                <Column field='content' className="code" body={contentBodyTemplate} header='Content'></Column>
                <Column field='deleted' header='Deleted'></Column>
                <Column body={actionBodyTemplate} header=""></Column>
            </DataTable>
        </div>
    )
}

const Content = () => {
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(3);
    const data = [
        { page: 'Page Name', target: 'Target Info', contentType: 'Content', deleted: 'Month 01, 2019' },
        { page: 'Page Name', target: 'Target Info', contentType: 'Content', deleted: 'Month 01, 2019' },
        { page: 'Page Name', target: 'Target Info', contentType: 'Content', deleted: 'Month 01, 2019' },
        { page: 'Page Name', target: 'Target Info', contentType: 'Content', deleted: 'Month 01, 2019' },
        { page: 'Page Name', target: 'Target Info', contentType: 'Content', deleted: 'Month 01, 2019' },
        { page: 'Page Name', target: 'Target Info', contentType: 'Content', deleted: 'Month 01, 2019' },
        { page: 'Page Name', target: 'Target Info', contentType: 'Content', deleted: 'Month 01, 2019' },
        { page: 'Page Name', target: 'Target Info', contentType: 'Content', deleted: 'Month 01, 2019' },
        { page: 'Page Name', target: 'Target Info', contentType: 'Content', deleted: 'Month 01, 2019' },
    ]

    const actionBodyTemplate = () => {
        return (
            <span >
                <button>RESTORE</button>
            </span>
        )
    }

    const contentBodyTemplate = () => {
        let content = '<h2 className="giveback-headline typography-eyebrow-super"><span role="text" aria-label="iPhone X R">iPhone&nbsp;X<span className="small-caps">R</span></span> from <span role="text" aria-label="nineteen dollars and ninety-nine cents per month">$19.99/mo.</span> or $479.</h2>';
        return (
            <div>
                <code>
                    {content}
                </code>
            </div>

        )
    }

    const paginatorTemplate = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 3, value: 3 },
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </>
            );
        },
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            )
        }
    };

    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    }

    return <View {...{ data, actionBodyTemplate, first, rows, paginatorTemplate, onCustomPage, contentBodyTemplate }} />
}

export default Content;