import { NavLink } from 'react-router-dom';
import PagesView from '../../components/pages-view/PagesView';
import './PagesPage.css';

const View = () => {
    return (
        <div className="pages-container">
            <div className="page-header flex items-baseline">
                <h1 className='text-center flex-1'>GEOFLI PAGES</h1>
                <button className='flex text-[#179ab7] border-solid border-2 border-[#179ab7] p-[8px_15px]'>Share <img className='p-[5px]' src="images/mail-send.png" /></button>
            </div>
            <div className="page-tip">
                <div className="blube-img">
                    <img src="images/blube.png" />
                </div>
                <div className="label">
                    <span>Check your Google Analytics top pages, and add them</span>
                    <a href="#">  here</a>
                    <span> to create engaging experiences.</span>
                    <NavLink to="/createpage">  + ADD PAGE</NavLink>
                </div>
                <img src="images/close.svg" className="close-div" />
            </div>


            <div className="view">
                <div className="view-body">
                    <PagesView />
                </div>
            </div>
            <div className="add-button">
            <NavLink to="/createpage">  <button> + Add Page</button></NavLink>
                
            </div>
        </div>
    )
}

const PagesPage = () => {
    return <View />
}

export default PagesPage;