

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useForm } from "react-hook-form";
import { Toast } from 'primereact/toast';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Calendar } from 'primereact/calendar';
import "./ContentEditModal.css";
import { useRef, useState } from 'react';
import { getAudiences, addPageContent } from '../../services/page.service';
import { getContentById, updateContent } from '../../services/content.service';

const ContentEditModal = ({ setIsOpen, selectedRow, refresh, setRefresh }) => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [city, setCity] = useState([]);
    const [content, setContent] = useState("");
    const [isEditor, setIsEditor] = useState(0);
    const [isCalender, setIsCalender] = useState(false);
    const [isCssSelector, setIsCssSelector] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [responseErrorMsg, setResponseErrorMsg] = useState([]);
    const [cities, setCities] = useState([]);
    const [nodeType, setNodeType] = useState('');
    const toast = useRef(null);
    
    const alignOptions = [
        { name: 'Left', code: 'left' },
        { name: 'Center', code: 'center' },
        { name: 'Right', code: 'right' },
    ]

    const positionOptions = [
        { name: 'Display below content', code: 'dbc' }
    ]

    const onShowDialog = () => {
        getAudiences().then(response => {
            if (response?.data) {
                setCities(response?.data?.data.filter(x => x.id != null));
            }
        })
        .catch(exception => {
            toast.current.show({severity: 'error', summary: 'Error', detail: exception?.response?.data.message, life: 3000});    
        })

        ClassicEditor
            .create(document.querySelector('.ck-editor'), {
                initialData: 'Edit content here...',
                removePlugins: ['Heading'],
                toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'strike', '|', 'justifyLeft', 'justifyCenter', 'justifyRight'],
            })
            .catch(error => {
                console.log(error);
            });

        getContentById(selectedRow).then(response => {
            if (response?.data) {
                let result = response?.data?.data;
                setValue('section_name', result.name);
                setValue('selector', result.selector);
                setValue('target_id', result.target_id);
                result.time_start && setIsCalender(true);
                result.time_start && setValue('calender', true);
                setIsEditor(result.content_type_id);
                setContent(result.content);
                setNodeType(result.node_type)
                setValue('start_date',  result.time_start?.split(' ')[0]);
                setValue('end_date',  result.time_end?.split(' ')[0]);
            }
        })
        .catch(exception => {
            toast.current.show({severity: 'error', summary: 'Error', detail: exception?.response?.data.message, life: 3000});    
        })
    }

    const onSubmit = (params) => {

        let payload = {
            id:selectedRow.id,
            page_id: selectedRow.page_id,
            content: isEditor == 1 ? content : getContent(params),
            selector: params.selector,
            selector_node_type: nodeType,
            selector_name: params.section_name,
            content_type_id: "1",
            target_id: params.target_id,
            time_start: params.start_date || "",
            time_end: params.end_date || ""
        }
        
        updateContent(payload).then(response => {
            if (response?.data) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Page content updated', life: 3000 });
                setTimeout(() => {
                    setIsVisible(false);
                    setIsOpen(false);
                    setRefresh(!refresh)
                }, 1000);
            }
        })
        .catch(exception => {
            setResponseErrorMsg(exception?.response?.data?.errors);

            if(!exception.response.data.hasOwnProperty('errors'))
                toast.current.show({severity: 'error', summary: 'Error', detail: exception?.response?.data?.message, life: 3000});   
        })

    }

    const getContent = (button) => {
        return `<button class="${button.button_class}" style='color:${button.button_textcolor};background-color:${button.button_color};position:${button.button_position};text-align:${button.button_align}'><NavLink to="${button.button_link}">  ${button.button_text} </NavLink></button>`
    }

    const onCityChange = (e) => {
        setCity(e.value);
    }

    const onHide = () => {
        setIsVisible(false);
        setIsOpen(false);
    }

    return (
        <Dialog className="modal-dialog" visible={isVisible} onShow={onShowDialog} onHide={onHide} breakpoints={{ '960px': '75vw' }} style={{ width: '75vw' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='section-modal'>
                    <div className="grid grid-cols-12 gap-4 py-4">
                        <div className="col-span-1 text-center">
                            <div className="bg-white shadow-2xl shadow-black rounded-full h-12 w-12 p-2.5 text-cyan-500 font-semibold text-xl">1</div>
                        </div>
                        <div className="col-span-11">
                            <div className="field form-input">
                                <input type="text" id="section_name" disabled aria-describedby="section_name" placeholder="Type your Area Name here…"
                                    name="section_name"
                                    {...register('section_name', { required: true })}
                                    className= {(errors.section_name?.type === 'required' || responseErrorMsg?.section_name?.length>0) ? "block w-full border-2 border-rose-600" : "block w-full input-field"}
                                />
                                <small id="section_name_help" className="block text-sm"><span className="text-cyan-500 font-semibold ">Pro Tip!</span> Clearly naming your page area allows for quick edits in your GeoFli future. <span className="float-right text-cyan-500 font-semibold cursor-pointer" onClick={() => setIsCssSelector(!isCssSelector)}><a>CSS Selector</a></span></small>
                                {(errors.section_name?.type === 'required' || responseErrorMsg?.section_name?.length>0) ? 
                                    (<i className="error-image tooltip"><span class="tooltiptext">
                                            {(responseErrorMsg?.section_name?.length>0) ? responseErrorMsg?.section_name : "This field is required."}</span></i> ) :""} 
                            </div>
                        </div>
                    </div>
                    {isCssSelector &&
                        <div className="grid grid-cols-12 gap-4 py-4">
                            <div className="col-span-1"></div>
                            <div className="col-span-11">
                                <div className="field form-input">
                                    <input type="text" id="selector" aria-describedby="selector" placeholder="Type css selector" disabled
                                        name="selector"
                                        {...register('selector', { required: true })} 
                                        className= {(errors.selector?.type === 'required' || responseErrorMsg?.selector?.length>0) ? "block w-full border-2 border-rose-600" : "block w-full input-field"}/>
                                {(errors.selector?.type === 'required' || responseErrorMsg?.selector?.length>0) ? 
                                    (<i className="error-image tooltip"><span class="tooltiptext">
                                            {(responseErrorMsg?.selector?.length>0) ? responseErrorMsg?.selector : "This field is required."}</span></i> ) :""}
                                </div>
                            </div>
                        </div>
                    }
                    <div className="grid grid-cols-12 gap-4 py-4">
                        <div className="col-span-1 text-center">
                            <div className="bg-white shadow-2xl shadow-black rounded-full h-12 w-12 p-2.5 text-cyan-500 font-semibold text-xl">2</div>
                        </div>
                        <div className="col-span-11">
                            <div className="field form-input">
                                <select placeholder="Select yout target Audience"
                                    name="target_id"
                                    {...register('target_id', { required: true })} 
                                    className= {(errors.target_id?.type === 'required' || responseErrorMsg?.target_id?.length>0) ? "block w-full border-2 border-rose-600 one cursor-pointer" : "block w-full input-field one cursor-pointer"} >
                                         <option key={0} value={''}>-- Select --</option>
                                        {cities?.length && cities.map((item) => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                </select>
                                {(errors.target_id?.type === 'required' || responseErrorMsg?.target_id?.length>0) ? 
                                    (<i className="error-image tooltip"><span class="tooltiptext">
                                        {(responseErrorMsg?.target_id?.length>0) ? responseErrorMsg?.target_id : "This field is required."}</span></i> ) :""}
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-4 py-4">
                        <div className="col-span-1 text-center">
                            <div className="bg-white shadow-2xl shadow-black rounded-full h-12 w-12 p-2.5 text-cyan-500 font-semibold text-xl">3</div>
                        </div>
                        <div className="col-span-11">
                            <div className="field">
                                {isEditor == 0 &&
                                    <>
                                        <label htmlFor="typeOfContent" className="block py-4">Select the type of content that you want to edit:
                                        {(errors.content?.type === 'required' || responseErrorMsg?.content?.length>0) ? 
                                            (<i className="error-image tooltipnext"><span class="tooltiptextnext">
                                            {(responseErrorMsg?.content?.length>0) ? responseErrorMsg?.content : "This field is required."}</span></i> ) :""}
                                        </label>
                                        
                                        <div className="grid grid-cols-4 gap-6 h-64">
                                            <div className="shadow-xl text-center py-12 cursor-pointer" onClick={() => setIsEditor(1)}>
                                                <div className="text-cyan-500 text-xl mx-12 font-semibold">Text</div>
                                                <div className="text-cyan-500 text-8xl mt-8">A</div>
                                            </div>
                                            <div className="shadow-xl text-center pb-12 pt-6 cursor-pointer" onClick={() => setIsEditor(2)}>
                                                <div className="text-cyan-500 text-xl mx-12 font-semibold">Add Custom Button</div>
                                                <div className="text-cyan-500 text-3xl mt-8">
                                                    <div>
                                                        <img src="images/rectangle.png" className="block ml-auto mr-auto mt-14" />
                                                    </div>
                                                    <img src="images/button.png" className="block ml-auto mr-auto relative bottom-6" />
                                                </div>
                                            </div>
                                            <div className="shadow-xl text-center pb-12 pt-6 cursor-pointer" onClick={() => setIsEditor(3)}>
                                                <div className="text-cyan-500 text-xl mx-12 font-semibold">Upload Button Image</div>
                                                <div className="text-cyan-500 text-3xl mt-8">
                                                    <img src="images/group-4.png" className="block ml-auto mr-auto" />
                                                </div>
                                            </div>
                                            <div className="shadow-xl text-center pb-12 pt-6 cursor-pointer" onClick={() => setIsEditor(4)}>
                                                <div className="text-cyan-500 text-xl mx-8 font-semibold">Upload Background Image</div>
                                                <div className="text-cyan-500 text-3xl mt-9">
                                                    <img src="images/group-5-2.png" className="block ml-auto mr-auto" />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {isEditor === 1 &&
                                    <>
                                        <label htmlFor="typeOfContent" className="block py-4"><span className="text-cyan-500 font-semibold cursor-pointer" onClick={() => setIsEditor(0)}>{'< Back to options    '}</span>Add text below
                                        {(errors.content?.type === 'required' || responseErrorMsg?.content?.length>0) ? 
                                            (<i className="error-image tooltipnext"><span class="tooltiptextnext">
                                            {(responseErrorMsg?.content?.length>0) ? responseErrorMsg?.content : "This field is required."}</span></i> ) :""}
                                        </label>
                                        
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data=""
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setContent(data);
                                            }}
                                            onReady={editor => {
                                                editor.setData(content);
                                                setContent(content);
                                            }}
                                        />
                                    </>
                                }
                                { isEditor == 2 &&
                                    <>
                                        <label htmlFor="typeOfContent" className="block py-4"><span className="text-cyan-500 font-semibold cursor-pointer" onClick={() => setIsEditor(0)}>{'< Back to options    '}</span>Upload button image</label>
                                        <div className="grid grid-cols-12 py-4">
                                            <div className="col-span-1">
                                                <label>Link</label>
                                            </div>
                                            <div className="col-span-11 field form-input">
                                                <input id="button_link" 
                                                        aria-describedby="link-help" 
                                                        className="block w-full input-field" 
                                                        placeholder="e.g. www.yoursite.com/register"
                                                        name="button_link"
                                                        {...register('button_link')}  />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-12 py-4">
                                            <div className="col-span-1">
                                                <label>Button Text</label>
                                            </div>
                                            <div className="col-span-11 field form-input">
                                                <input id="button_text" 
                                                    aria-describedby="link-help" 
                                                    className="block w-full input-field" 
                                                    placeholder="e.g. REGISTER HERE"
                                                    name="button_text" 
                                                    {...register('button_text')} />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-12 py-4">
                                            <div className="col-span-1">
                                                <label>Button Color</label>
                                            </div>
                                            <div className="col-span-2">
                                                <input type="color" 
                                                    id="button_color" 
                                                    name="button_color" 
                                                    {...register('button_color')} />
                                            </div>
                                            <div className="col-span-1">
                                                <label>Text Color</label>
                                            </div>
                                            <div className="col-span-2">
                                                <input type="color" 
                                                    id="button_textcolor" 
                                                    name="button_textcolor" 
                                                    {...register('button_textcolor')} />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-12 py-4">
                                            <div className="col-span-1">
                                                <label>Align</label>
                                            </div>
                                            <div className="col-span-5 field form-input">
                                                <select placeholder="Select Align option"
                                                    name="button_align"
                                                    className='input-field'
                                                    {...register('button_align')} >
                                                        <option key={0} value={''}>-- Select --</option>
                                                        {alignOptions?.length && alignOptions.map((item) => (
                                                            <option key={item.code} value={item.code}>{item.name}</option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div className="col-span-6"></div>
                                        </div>
                                        <div className="grid grid-cols-12 py-4">
                                            <div className="col-span-1">
                                                <label>Position</label>
                                            </div>
                                            <div className="col-span-5 field form-input">
                                                <select placeholder="Select position"
                                                    name="button_position"
                                                    className='input-field'
                                                    {...register('button_position')} >
                                                        <option key={0} value={''}>-- Select --</option>
                                                        {positionOptions?.length && positionOptions.map((item) => (
                                                            <option key={item.code} value={item.code}>{item.name}</option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div className="col-span-6"></div>
                                        </div>
                                        <div className="grid grid-cols-12 py-4">
                                            <div className="col-span-1">
                                                <label>Button Class</label>
                                            </div>
                                            <div className="col-span-5 field form-input">
                                                <input id="button_class" 
                                                    aria-describedby="link-help" 
                                                    className="block w-full input-field" 
                                                    placeholder="You can add a css class here"
                                                    name="button_class"
                                                    {...register('button_class')} />
                                            </div>
                                            <div className="col-span-6"></div>
                                        </div>
                                    </>
                                }
                                {isEditor == 3 &&
                                    <>
                                        <label htmlFor="typeOfContent" className="block py-4"><span className="text-cyan-500 font-semibold" onClick={() => setIsEditor(0)}>{'< Back to options    '}</span>Upload button image</label>
                                        <div className="grid grid-cols-12 py-4">
                                            <div className="col-span-3">
                                                <label>Link</label>
                                            </div>
                                            <div className="col-span-9">
                                                <InputText id="link" aria-describedby="link-help" className="block w-full input-field" placeholder="e.g. www.yoursite.com/register" />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-12 py-4">
                                            <div className="col-span-3">
                                                <label>Button Image</label>
                                            </div>
                                            <div className="col-span-9">
                                                <button className="px-56 h-14 border-2 text-cyan-500 font-semibold text-xl input-field" > Upload button image
                                                    <img src="images/line-copy.png" className="block ml-4 float-right" />
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                }
                                {isEditor == 4 &&
                                    <>
                                        <label htmlFor="typeOfContent" className="block py-4"><span className="text-cyan-500 font-semibold" onClick={() => setIsEditor(0)}>{'< Back to options    '}</span>Upload background image below</label>
                                        <div className="grid grid-cols-12 py-4">
                                            <div className="col-span-12">
                                                <button className="px-56 h-14 border-2 text-cyan-500 font-semibold text-xl input-field" > Upload background image
                                                    <img src="images/line-copy.png" className="block ml-4 float-right" />
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-4 py-4">
                        <div className="col-span-1 text-center">
                            <div className="bg-white shadow-2xl shadow-black rounded-full h-12 w-12 p-2.5 text-cyan-500 font-semibold text-xl">4</div>
                        </div>
                        <div className="col-span-11">
                     
                            <div className="field pt-4">
                                <input type="checkbox" {...register('calender')}  onChange={() => setIsCalender(!isCalender)}  className="h-4 w-4 border-2 border-cyan-500 cursor-pointer" />
                                <label htmlFor="schedule" className="p-checkbox-label p-2.5 text-cyan-500 font-semibold">Schedule</label>
                            </div>
                            {isCalender &&
                                <div className="grid grid-cols-12 py-4">
                                    <div className="col-span-2">
                                        {/* <Calendar id="start_date" name='start_date' className="w-full input-field" showIcon placeholder="Start Date" 
                                         {...register('start_date', { valueAsDate: true })} /> */}
                                         <input type="date" id="start_date" name='start_date' {...register('start_date', { valueAsDate: true })} className="w-full input-field input-date" placeholder="Start Date" />
                                    </div>
                                    <label className="text-2xl text-center pt-4">&</label>
                                    <div className="col-span-2">
                                        {/* <Calendar id="end_date" name='end_date' className="w-full input-field" showIcon placeholder="End Date" 
                                        value={endDate} onChange={(e) => setEndDate(e.value)} /> */}
                                        <input type="date" id="end_date" name='end_date' {...register('end_date', { valueAsDate: true })} className="w-full input-field input-date" placeholder="End Date" />
                                    </div>
                                    <div className="col-span-8"></div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="text-center py-4">
                    <button type="submit" className="bg-lime-500 text-white px-56 h-12" >UPDATE</button>
                </div>
            </form>
            <Toast ref={toast} />
        </Dialog>
    )
}

export default ContentEditModal;