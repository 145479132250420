import React, { useState } from 'react';
import { DataView } from 'primereact/dataview';
import { GridItem, HeaderTemplate, ListItem } from '../content-view/ContentView';
import './DataView.css';

const View = ({ data, layout, header, itemTemplate, setLayout }) => {
    return(
        <>
            <div className='view-header'>
                <div className="flex-1 flex justify-end">
                    {layout === 'list'
                        ? <div className='gride-tab-menu'>
                            <div className='gride-tab-menu-active' onClick={() => setLayout('list')}>
                                <img src="images/group-9.png" />
                                <span>LIST VIEW</span>
                            </div>
                            <div onClick={() => setLayout('grid')}>
                                <img src="images/grid-view.png" />
                                <span>GRID VIEW</span>
                            </div>
                        </div>
                        : <div className='gride-tab-menu'>
                            <div onClick={() => setLayout('list')}>
                                <img src="images/group-9.png" />
                                <span>LIST VIEW</span>
                            </div>
                            <div className='gride-tab-menu-active' onClick={() => setLayout('grid')}>
                                <img src="images/grid-view.png" />
                                <span>GRID VIEW</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="dataview">
                <div className="dataview-card">
                    <DataView value={data} layout={layout} header={header}
                        itemTemplate={itemTemplate} paginator rows={9} className="data-view" />
                </div>
            </div>
        </>
    )
} 

const renderHeader = (layout, columns) => {
    return(
        <>
            {layout === 'list' &&
                <HeaderTemplate columns={columns}/>
            }
        </> 
    )
}

const ItemDataView = ({ data, columns, confirm, setVisible, setSelectedRow, setIsOpen }) => {
    const [layout, setLayout] = useState('list');
    const header = renderHeader(layout, columns);
    const itemTemplate = (data, layout) => {
        if(!data){
            return;
        }  
        return layout === 'list' ? <ListItem data={data} confirm={confirm} setSelectedRow={setSelectedRow} setVisible={setVisible} setIsOpen={setIsOpen} /> 
        : <GridItem data={data} confirm={confirm} setSelectedRow={setSelectedRow} setVisible={setVisible} setIsOpen={setIsOpen} />;            
    }

    return <View {...{ data, layout, header, itemTemplate, setLayout }} />
}

export default ItemDataView;