import './Footer.css';

const Footer = () => {
	return (
		<div className="footer flex justify-between">
			<div className='flex-1'>
				<ul className='flex footer-left-link'>
					<li><a href='#'>Getting started </a></li>
					<li><a href='#'>FAQ</a> </li>
					<li><a href='#'>Videos </a> </li>
				</ul>
			</div>
			<div className='flex-1'>
				<ul className='flex text-white justify-end'>
					<li><a href='#'>Privacy Policy </a></li>
					<li><a href='#'>- Terms & Conditions</a> </li>
				</ul>
			</div>
		</div>
	);
};


export default Footer;
