import './Referral.css';

const View = () => {
    return (
        <div className='right-box-sh'>
            <div className='img100'>
                <img src="images/architecture.png" />
            </div>
            <div className="general-lage-text"><span>Share & Get Rewarded</span></div>


            <div className='month-free'>Get one <span>month free </span> for each referral that<br></br> becomes a member</div>
            <div className='general-right-input'>
                <input type="text" placeholder="Referral’s Name" />
                <input type="text" placeholder="Referral’s Email" />
                <button>SEND</button>
            </div>
        </div>
    )
}

const Referral = () => {
    return <View />
}

export default Referral;