import axios from 'axios';

const account = localStorage.getItem('account_id');

const addPage = async (payload) => {
	return await axios.post('/v1/accounts/' + [account] + '/pages', payload).then((res) => res);
};
const getPage = async () => {
	return await axios.get('/v1/accounts/' + [account] + '/pages').then((res) => res);
};

const addAudiences = async (payload) => {
	return await axios.post('/v1/accounts/' + [account] + '/audiences', payload).then((res) => res);
};
const getAudiences = async () => {
	return await axios.get('/v1/accounts/' + [account] + '/audiences').then((res) => res);
};

const addPageContent = async (payload) => {
	return await axios.post(`/v1/accounts/${account}/pages/${payload.page_id}/content`, payload).then((res) => res);
};

const getProxyURL = async (pageURL) => {
	return await axios.get(`/v1/proxy?url=${pageURL}`).then((res) => res);
};

const deleteAudience = async (id) => {
	return await axios.delete(`/v1/accounts/${account}/audiences/${id}`).then((res) => res);
};

const getAudienceById = async (id) => {
	return await axios.get(`/v1/accounts/${account}/audiences/${id}`).then((res) => res);
};

const updateAudiences = async (payload) => {
	return await axios.put(`/v1/accounts/${account}/audiences/${payload.id}`, payload).then((res) => res);
};

export { addPage, getPage, addAudiences, getAudiences,addPageContent, getProxyURL, deleteAudience, getAudienceById, updateAudiences };
