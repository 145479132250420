import React, { useState } from 'react';
import { Chart } from 'primereact/chart';
import './AudiencesChart.css';

const View = ({ chartOptions, chartData }) => {
    return(
        <div className="audiences-chart">
            <div className='audiences-chart-header'>
                <div className='audiences-chart-header-highlight'>
                    <span className=''>Total Sessions</span>
                    <span>140.3k</span>
                </div>
                <div className='audiences-chart-header-dim'>
                    <span className=''>TOTAL AUDIENCES</span>
                    <span>4</span>
                </div>
            </div>
            <div className='body'>

                <div className='p-tabview-panel'>            
                        <div className='p-chart'>
                    <Chart type="bar" data={chartData} options={chartOptions} />
                </div>
                <div>
                    <button className="border-dashed border-2 border-2xl border-cyan-500 text-cyan-500">
                        <img src="images/copy-4.png"></img>
                        Add Audience
                    </button>
                </div>

                </div>

            </div>
        </div>
    )
}

const AudiencesChart = () => {
    const { chartOptions } = getLightTheme();
    const [chartData] = useState({
        labels: ['Southwest', 'New York', 'Pacefic NorthWest', 'La'],
        datasets: [
            {
                label: '',
                backgroundColor: [
                    'rgba(67,203,10,0.7)',
                    'rgba(67,203,10,0.6)',
                    'rgba(67,203,10,0.5)',
                    'rgba(67,203,10,0.4)'
                ],
                data: [80, 59, 47, 20]
            }
        ]
    });
    return <View {...{chartOptions, chartData}}/>;
}

const getLightTheme = () => {
    let chartOptions = {
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            legend: {
                labels: {
                    color: '#179ab7'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#179ab7'
                },
                grid: {
                    display: false,
                    drawBorder: false
                },
            },
            y: {
                display: false,
                grid: {
                    display: false,
                }
            }
        }
    };

    return {
        chartOptions
    }
};

export default AudiencesChart;