import { useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import GeotargettingInsights from '../../components/geotargetting-insights/GeotargettingInsights';
import Membership from '../../components/general-membership/Membership';
import Referral from '../../components/general-referral/Referral';
import RegisteredUsers from '../../components/registered-users/RegisteredUsers';
import InviteUser from '../../components/invite-user/InviteUser';
import './AccountPage.css';
import Billing from '../../components/billing/Billing';
import TargetedAreas from '../../components/targeted-areas/TargetedAreas';
import Selectors from '../../components/selectors/Selectors';
import Content from '../../components/content/Content';

const View = ({ activeIndex, setActiveIndex, tabHeader }) => {
    return (
        <div className='account-page'>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header={tabHeader('General', 'images/settings-1.png', '18px')}>
                    <div className='general-tab-container'>
                        <div className='general-left'>
                            <Membership />
                        </div>
                        <div className='general-right'>
                            <Referral />
                        </div>
                    </div>
                    <div className='general-tab-info'>
                        <GeotargettingInsights />
                    </div>
                </TabPanel>
                <TabPanel header={tabHeader('Users', 'images/high-five.png', '22px')}>
                    <div className='userTabContainer'>
                        <div className='userTabLeft'><RegisteredUsers /></div>
                        <div className='userTabRight'> <InviteUser /></div>
                    </div>
                </TabPanel>
                <TabPanel header={tabHeader('Billing', 'images/replace.png', '14px')}>
                    <Billing />
                </TabPanel>
                <TabPanel header={tabHeader('Restore Assets', 'images/delete-1.png', '14px')}>
                    <TargetedAreas />
                    <Selectors />
                    <Content />
                </TabPanel>
            </TabView>
        </div>
    )
}



const AccountPage = () => {
    const [activeIndex, setActiveIndex] = useState(2);

    const tabHeader = (title, icon, iconWidth) => {
        return (
            <>
                <img style={{ 'width': iconWidth }} src={icon} />
                <span>{title}</span>
            </>
        )
    }

    return <View {...{ activeIndex, setActiveIndex, tabHeader }} />;
}

export default AccountPage;