import { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import PageArea from '../pageArea/pageArea';
import { getAudiences } from '../../services/page.service';
import './AddPageContent.css';

const View = ({ setActiveIndex, activeContent, data, optionTemplate, selectedTemplate, onChange, onAddAudience, dropdowns, pageURL, pageId }) => {
    return (
        <>
            {activeContent === 1 &&
                <div className="add-content-container">
                    <div className="page-header">
                        <h1>Now let’s set for each target audience the redirect url<img src="./images/blube.png" /></h1>
                    </div>
                    <div className="page-body mt-5">
                        <div className='box-header'>
                            <div><img src="./images/edit-3-copy-3.png" /></div>
                            <div className='header-label'>Page Name with words and information</div>
                            <div className='header-label bg-white'>www.sitedomain.com/page-name</div>
                        </div>
                        <div className='box-body'>
                            {dropdowns.length && dropdowns.map((item, index) => {
                                return (
                                    <> <div className='flex' key={index}>

                                        <div className='flex-1 flex first'>
                                            <Dropdown
                                                value={item.selected}
                                                options={data}
                                                onChange={(e) => onChange(item.id, e)}
                                                optionLabel="id"
                                                panelClassName="panel"
                                                filter
                                                showClear
                                                filterBy="radius"
                                                placeholder="Select Audience"
                                                valueTemplate={selectedTemplate}
                                                itemTemplate={optionTemplate} />

                                        </div>
                                        <div className='flex-1 redirect'> &gt; Redirects to &gt;</div>
                                        <div className='flex-1'>
                                            <input className='input' type="text" placeholder="Type Redirect URL here…" />
                                        </div>

                                    </div>
                                    </>
                                )
                            })
                            }
                            <div className='btn-add-audience-div'><button className='btn-add-audience' onClick={onAddAudience}>+ ADD AUDIENCE</button></div>
                            <div className='mt-16 text-center'><button className='btn-continue' onClick={() => setActiveIndex(3)}>CONTINUE</button></div>
                        </div>
                    </div>
                </div>
            }
            {activeContent === 0 &&
                <div className="add-content-container">
                    <div className="page-header">
                        <h1>Move your cursor over your page below, you will see a blue indicator, </h1>
                        <h1>select the page area that you want to personalize by clicking on it.</h1>
                    </div>
                    <div className="page-body mt-5">
                        <PageArea pageURL={pageURL} pageId={pageId} />
                    </div>
                </div>
            }
        </>
    )
}

const AddPageContent = ({ setActiveIndex, activeContent, pageURL, pageId }) => {
    const [data, setData] = useState([]);
    const [dropdowns, setDropdowns] = useState([{ id: 1, selected: null }]);
    useEffect(() => {
        getAudiences().then(response => {
            let responseData = [];
            if (response?.data) {
                for (let i = 0; i < response.data.data.length; i++) {
                    responseData.push({ id: response.data.data[i].id, city: "NA", radius: "100" });
                }
                setData(responseData);
            }
        })
    }, []);

    const optionTemplate = (option) => {
        return (
            <>
                <div className='label'>{option.city}</div>
                <div className='flex img'>
                    <img src="./images/placeholder-copy.png" />
                    <span className='ml-2'>{option.radius} miles Radius</span>
                </div>
            </>
        );
    }

    const selectedTemplate = (option, props) => {
        if (option) {
            return (
                <>
                    <div className='label'>{option.city}</div>
                    <div className='flex img'>
                        <img src="./images/placeholder-copy.png" />
                        <span className='ml-2'>{option.radius} miles Radius</span>
                    </div>
                </>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const onChange = (id, e) => {
        const newState = dropdowns.map(obj => {

            if (obj.id === id) {
                return { ...obj, selected: e.value };
            }

            return obj;
        });
        setDropdowns(newState);
    }

    const onAddAudience = () => {
        setDropdowns([...dropdowns, { id: dropdowns.length + 1, selected: null }]);
    }

    return <View {...{ setActiveIndex, activeContent, data, optionTemplate, selectedTemplate, onChange, onAddAudience, dropdowns, pageURL, pageId }} />
}

export default AddPageContent;