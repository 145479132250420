import axios from 'axios';

const login = async (payload) => {
	return await axios.post('/v1/login', payload).then((res) => res);
};

const register = async (payload) => {
	return await axios.post('/v1/register', payload).then((res) => res);
};

const forgotPassword = async (payload) => {
	return await axios.post('/forgot-password', payload).then((res) => res);
};

const resetPassword = async (payload) => {
	return await axios.post('/reset-password', payload).then((res) => res);
};

export { login, register, forgotPassword, resetPassword };
