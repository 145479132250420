import AudiencesListView from '../../components/audiences-list-view/AudiencesListView';
import MapView from '../../components/map-view/MapView';
import AudiencesChart from '../../components/audiences-chart/AudiencesChart';
import './AudiencesPage.css';

const AudiencesPage = () => {
    return (
        <div className="audiences-container">
            <div className="page-header">
                <h1>AUDIENCES </h1>
            </div>
            <div className='map-view'>
                <MapView />
                <AudiencesChart />
            </div>
            <AudiencesListView />
            <div className="add-button">
                <button> + Add Audience
                    <div className="page-tip">
                        <div>
                            <img src="images/blube.png" />
                        </div>
                        <div className="label">
                            <span>Check your Google Analytics top target audiences, and add them</span>
                            <a href="#">  here</a>
                            <span> to increase conversion rates.</span>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    )
}

export default AudiencesPage;