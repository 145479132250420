import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useEffect } from 'react';
import { useState } from 'react';
import { deleteContent, getContents } from '../../services/content.service';
import ItemDataView from "../data-view/DataView";
import { Toast } from 'primereact/toast';
import './ContentView.css';
import { useRef } from 'react';
import ContentEditModal from '../content-edit-modal/ContentEditModal';

const View = ({ data, columns, confirm, confirmDialogMessageTemplate, confirmDialogHeaderTemplate, accept, reject, visible, setVisible, setSelectedRow, toast, isOpen, setIsOpen, selectedRow, refresh, setRefresh }) => {
    return (
        <>
            <ItemDataView {...{ data, columns, confirm, setVisible, setSelectedRow, setIsOpen }} />
            <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message={confirmDialogMessageTemplate}
                    header={confirmDialogHeaderTemplate} accept={accept} reject={reject} 
                    acceptClassName='acceptbutton' rejectClassName='rejectbutton' className='confirm-modal-dialog' />
            <Toast ref={toast} />
            {isOpen &&
                <ContentEditModal {...{ setIsOpen, selectedRow, refresh, setRefresh }} />
            }
        </>
    )
}

const ListItem = ({ data, confirm, setVisible, setSelectedRow, setIsOpen  }) => {
    return (
        <div className='content-list-item'>
            <div className='content-list-search'>
                <img src={data.icon} />
            </div>
            <div>{data.selector_name}</div>
            <div>{data.pagearea}</div>
            <div>{data.page_name}</div>
            <div>{data.region_name}</div>
            <div>
                <button onClick={() => {setIsOpen(true);setSelectedRow(data);}}>
                    <img src="images/edit-3-copy-4.png" />Edit
                </button>
                <button onClick={() => {setVisible(true);setSelectedRow(data);}}>
                    <img src="images/delete.png" />Delete
                </button>
                {/* <button>
                    <img src="images/oval.png" />Content Grid
                </button> */}
            </div>
        </div>
    )
}

const GridItem = ({ data, confirm, setVisible, setSelectedRow, setIsOpen }) => {
    return (
        <div className='content-grid-item'>
            {/* <div className='content-grid-item-header'>
                <img src={data.icon} />
                <span> {data.text}</span>
            </div> */}
            <div className='content-grid-item-info'>
                <label> For page area:</label>
                <span>{data.selector_name}</span>
            </div>
            <div className='content-grid-item-info'>
                <label> Audience:</label>
                <span>{data.region_name}</span>
            </div>
            <div className='content-grid-item-info'>
                <label>Page name:</label>
                <span>{data.page_name}</span>
            </div>

            {/* <div className='progress-bar-container'>
                <div className='progress-bar'>
                    <div className="percent-card">
                        <div className="percent">
                            <svg>
                                <circle cx="30" cy="30" r="25" />
                                <circle cx="30" cy="30" r="25" style={{ '--percent': data['percentage'] }} />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='progress-bar-info'> <span> {data.percentage}%</span> of content pending to be added</div>
            </div> */}
            
            {/* <div className='content-grid-item-click-info'>
                <div> <span> CLICKS</span> <label> {data.clicks}</label></div>
                <div> <span> SESSIONS</span> <label> {data.sessions}</label></div>
            </div> */}
            <div className='content-grid-item-btn'>
                {/* <button>
                    <img src="images/oval.png" />
                    CONTENT GRID
                </button> */}
                <button onClick={() => {setVisible(true);setSelectedRow(data);}}>
                    <img src="images/delete.png" />
                    Delete
                </button>
            </div>
        </div>
    )
}

const HeaderTemplate = ({ columns }) => {
    return (
        <div className="content-list-item-header">
            <div className='content-list-search' key={'search'}>
                <img src="images/search-copy.png" />
            </div>
            {columns.map((item) => (
                <div key={item.field}>{item.header}</div>
            ))}
            <div key={'action'}>Action</div>
        </div>
    )
}

const ContentView = () => {
    const toast = useRef(null);
    const [refresh, setRefresh] = useState(false);
    const [visible, setVisible] = useState(false); 
    const [selectedRow, setSelectedRow] = useState({}); 
    const [data, setData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
   
    useEffect(() => {
        let payload = {
            page_id: 0
        }
        getContents(payload).then(response=>{
            if(response?.data) {
                setData(response?.data?.data);
            }
         })
    }, [refresh]);

    const columns = [
        { field: 'selector_name', header: 'Name' },
        { field: 'pagearea', header: 'Page Area' },
        { field: 'page_name', header: 'Page' },
        { field: 'region_name', header: 'Audience(All)' },
    ];

    const accept = () => {

        deleteContent(selectedRow).then(response => {         
            if(response?.data){  
                toast.current.show({severity:'success', summary: 'Success', detail:response?.data?.message, life: 3000});
                setVisible(false);
                setRefresh(!refresh);
            }            
        })
        .catch(exception => {       
            if(!exception.response?.data?.hasOwnProperty('errors'))
                toast.current.show({severity: 'error', summary: 'Error', detail: exception?.response?.data.message, life: 3000});
        })
    }

    const reject = () => {

    }

    // const confirm = () => {
    //     confirmDialog({
    //         message: confirmDialogMessageTemplate(),
    //         header: confirmDialogHeaderTemplate(),
    //         accept,
    //         reject,
    //         acceptClassName: 'acceptbutton',
    //         rejectClassName: 'rejectbutton',
    //         className: 'confirm-modal-dialog'
    //     });
    // };

    const confirmDialogMessageTemplate = () => {
        return (
            <div className='delete-model-info' onClick={() => accept()}>
                <div className='delete-model-info-text'> <span>Important: </span>You will lost all data related to this content</div>
                <button className='delete-large-btn'><img src="images/red-delete.png" /> DELETE</button>
            </div>
        )
    }

    const confirmDialogHeaderTemplate = () => {
        return (
            <div className='delete-model-header'>
                <span>Are you sure you want <div>to delete your content?</div></span>
            </div>
        )
    }

    return <View {...{ data, columns, confirm, confirmDialogMessageTemplate, confirmDialogHeaderTemplate, accept, reject, visible, setVisible, setSelectedRow, toast, isOpen, setIsOpen, selectedRow, refresh, setRefresh }} />
}

export {
    ContentView,
    ListItem,
    GridItem,
    HeaderTemplate
}