import Footer from "../layout/footer/Footer";
import { useForm } from "react-hook-form";
import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { login } from "../../services/auth.service";
import './SignIn.css';
import {useEffect, useRef,useState} from 'react';
import {Toast} from 'primereact/toast';

const View = ({register, handleSubmit, onSubmit, errors,toast, responseErrorMsg }) => {
    return (
        <div>
            <div className="flex">
                <div className="w-72 h-screen bg-white pt-8 pr-8 pb-8 pl-12">
                    <div>
                        <img src="images/artboard-9-copy-copy@2x.png" />
                    </div>
                </div>                
                <div className="flex-1 login-signup-bg">
                    <div className="login-signup-container">
                        <div className="form-login">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="login-heaer">Welcome back!</div>                                
                                <div className="form-input user-icon">
                                    <input
                                        type="text"
                                        placeholder="Email"
                                        name="email"
                                        {...register('email', { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i })}
                                        className= {(errors.email?.type === 'required' || responseErrorMsg?.email?.length>0 || errors.email?.type === 'pattern') ? "border-2 border-rose-600" : ""}
                                    />
                                    {(errors.email?.type === 'required' || responseErrorMsg?.email?.length>0 || errors.email?.type === 'pattern') ? 
                                              (<i className="error-image tooltip"><span className="tooltiptext">
                                                {(responseErrorMsg?.email?.length>0) ? responseErrorMsg?.email : (errors.email?.type === 'pattern')?"This field is invalid." : "This field is required."}</span></i> ) :""} 
                                   
                                </div>
                                <div className="form-input password-icon">
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        {...register('password', { required: true })}
                                        className= {(errors.password?.type === 'required' || responseErrorMsg?.password?.length>0)? "border-2 border-rose-600" : ""}
                                    />                                    
                                    {(errors.password?.type === 'required' || responseErrorMsg?.password?.length>0) ? 
                                              (<i className="error-image tooltip"><span className="tooltiptext">
                                                {responseErrorMsg?.password?.length>0 ? responseErrorMsg?.password : "This field is required."}</span></i> ) :""}
                                </div>
                                <button type="submit" className="login-orange">LOGIN</button>
                                <div className="flex justify-between font-[18px] p-[5px_0px] uppercase">
                                    <NavLink to="/signup" className="text-[#ec920a]">Sign Up</NavLink>
                                    <NavLink to="/forgot-password" className="text-[#ec920a]">Forgot Password</NavLink>
                                </div>
                            </form>
                            
                        </div>
                        <div className="login-card-row">
                            <div className="login-card">
                                <div className="login-card-header"> fun fact</div>
                                <div className="login-card-bg"> <img src="images/goal-1.png" />
                                    <span>Mount Everest is the tallest mountain on Earth, the highest point on Earth. But when you remember that Earth is slightly oval-shaped, things get interesting.
                                        <a href="#">Learn More</a>
                                    </span>
                                </div></div>
                            <div className="login-card">
                                <div className="login-card-header">GeoTrends</div>
                                <div className="login-card-bg">
                                    <img src="images/stat.png" />
                                    <span>The lates search trends in you area are:
                                        <br />Topic One<br />
                                        Topic Two.<br />
                                        You can customize content based on these topic
                                        <a href="#">here</a>.
                                    </span>
                                </div>
                            </div>
                            <div className="login-card">
                                <div className="login-card-header">Geomarketing tip</div>
                                <div className="login-card-bg">
                                    <img src="images/chart.png" />
                                    <span>Check your Google Analytics top pages, and add them
                                        <a href="#">here</a> to create engaging experiences.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Footer />
            </div>
            <Toast ref={toast}></Toast>
        </div>
    )
}

const SignIn = () => {
    const toast = useRef(null); 
    const [responseErrorMsg, setResponseErrorMsg] = useState([]);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (localStorage.getItem('token')) {
            navigate('/dashboard');
        }
    },[location])

    const onSubmit = (params) => {;
        login(params).then(response => {            
            if(response?.data){  
                localStorage.setItem('token',response?.data?.token);
                localStorage.setItem('account_id',response?.data?.account.id);  
                localStorage.setItem('user_id',response?.data?.user.id);                     
                navigate('/dashboard');
            }            
        })
        .catch(exception => {        
            setResponseErrorMsg(exception.response.data.errors);

            if(!exception.response.data.hasOwnProperty('errors'))
                toast.current.show({severity: 'error', summary: 'Error', detail: exception?.response?.data.message, life: 3000});          
        })
    }
    return <View {...{ register, handleSubmit, onSubmit, errors,toast,responseErrorMsg}} />
}

export default SignIn;