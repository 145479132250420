import Footer from "../layout/footer/Footer";
import { useForm } from "react-hook-form";
import {useEffect, useRef,useState} from 'react';
import {Toast} from 'primereact/toast';
import { forgotPassword } from "../../services/auth.service";
import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import './ForgotPassword.css';

const View = ({register, handleSubmit, onSubmit, errors,toast, responseErrorMsg, responseMessage }) => {
    return (
        <div>
            <div className="flex">
                <div className="w-72 h-screen bg-white pt-8 pr-8 pb-8 pl-12">
                    <div>
                        <img src="images/artboard-9-copy-copy@2x.png" />
                    </div>
                </div>                
                <div className="flex-1 login-signup-bg">
                    <div className="login-signup-container">
                        {responseMessage === '' 
                            ? <div className="login-heaer forgot-password">Send Password Reset</div>
                            : <div className="login-heaer forgot-password">{responseMessage}</div>
                        }  
                        <div className="form-login">
                            <form onSubmit={handleSubmit(onSubmit)}>                             
                                {responseMessage === '' &&
                                    <>
                                        <div className="form-input user-icon">
                                            <input
                                                type="text"
                                                placeholder="Email"
                                                name="email"
                                                {...register('email', { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i })}
                                                className={(errors.email?.type === 'required' || responseErrorMsg?.email?.length > 0 || errors.email?.type === 'pattern') ? "border-2 border-rose-600" : ""}
                                            />
                                            {(errors.email?.type === 'required' || responseErrorMsg?.email?.length > 0 || errors.email?.type === 'pattern') ?
                                                (<i className="error-image tooltip"><span className="tooltiptext">
                                                    {(responseErrorMsg?.email?.length > 0) ? responseErrorMsg?.email : (errors.email?.type === 'pattern') ? "This field is invalid." : "This field is required."}</span></i>) : ""}

                                        </div>
                                        <button type="submit" className="login-orange">CONTINUE</button>
                                        <div className="flex justify-center font-[18px] p-[5px_0px] uppercase">
                                            <NavLink to="/signin" className="text-[#ec920a]">Sign In</NavLink>
                                        </div>
                                    </>
                                }
                            </form>
                        </div>
                        <div className="login-card-row">
                            <div className="login-card">
                                <div className="login-card-header"> fun fact</div>
                                <div className="login-card-bg"> <img src="images/goal-1.png" />
                                    <span>Mount Everest is the tallest mountain on Earth, the highest point on Earth. But when you remember that Earth is slightly oval-shaped, things get interesting.
                                        <a href="#">Learn More</a>
                                    </span>
                                </div></div>
                            <div className="login-card">
                                <div className="login-card-header">GeoTrends</div>
                                <div className="login-card-bg">
                                    <img src="images/stat.png" />
                                    <span>The lates search trends in you area are:
                                        <br />Topic One<br />
                                        Topic Two.<br />
                                        You can customize content based on these topic
                                        <a href="#">here</a>.
                                    </span>
                                </div>
                            </div>
                            <div className="login-card">
                                <div className="login-card-header">Geomarketing tip</div>
                                <div className="login-card-bg">
                                    <img src="images/chart.png" />
                                    <span>Check your Google Analytics top pages, and add them
                                        <a href="#">here</a> to create engaging experiences.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Footer />
            </div>
            <Toast ref={toast}></Toast>
        </div>
    )
}

const ForgotPassword = () => {
    const toast = useRef(null); 
    const [responseErrorMsg, setResponseErrorMsg] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (localStorage.getItem('token')) {
            navigate('/dashboard');
        }
    },[location])

    const onSubmit = (params) => {
        forgotPassword(params).then(response => {            
            if(response?.data){  
                setResponseMessage(response?.data?.message);
                toast.current.show({ severity: 'success', summary: 'Success', detail: response?.data?.message, life: 3000 });
            }            
        })
        .catch(exception => {     
            setResponseErrorMsg(exception.response.data.errors);  
            if(!exception.response.data.hasOwnProperty('errors'))
                toast.current.show({severity: 'error', summary: 'Error', detail: exception?.response?.data.message, life: 3000});          
        })
    }
    return <View {...{ register, handleSubmit, onSubmit, errors,toast,responseErrorMsg, responseMessage}} />
}

export default ForgotPassword;