import { Routes, BrowserRouter as Router, Route, Navigate } from "react-router-dom";
import PageLayout from '../components/layout/pageLayout/PageLayout';
import PageArea from "../components/pageArea/pageArea";
import SignIn from "../components/signin/SignIn";
import Signup from "../components/signup/Signup";
import AccountPage from "../pages/account/AccountPage";
import AnalyticsPage from "../pages/analytics/AnalyticsPage";
import AudiencesPage from "../pages/audiences/AudiencesPage";
import ContentPage from "../pages/content/ContentPage";
import DashboardPage from "../pages/dashboard/DashboardPage";
import DemoPage from "../pages/demo/DemoPage";
import PagesPage from "../pages/pages/PagesPage";
import CreatePage from "../pages/createPage/CreatePage";
import ForgotPassword from "../components/forgot-password/ForgotPassword";
import RecoverPassword from "../components/recover-password/RecoverPassword";


const AppRouter = () => {
  return (
    <Router>
        <Routes>
          <Route path="/dashboard" element={<PrivateRoute><PageLayout><DashboardPage /></PageLayout></PrivateRoute>}></Route>
          <Route path="/pages" element={<PrivateRoute><PageLayout><PagesPage /></PageLayout></PrivateRoute>}></Route>
          <Route path="/audiences" element={<PrivateRoute><PageLayout><AudiencesPage /></PageLayout></PrivateRoute>}></Route>
          <Route path="/content" element={<PrivateRoute><PageLayout><ContentPage /></PageLayout></PrivateRoute>}></Route>
          <Route path="/analytics" element={<PrivateRoute><PageLayout><AnalyticsPage /></PageLayout></PrivateRoute>}></Route>
          <Route path="/account" element={<PrivateRoute><PageLayout><AccountPage /></PageLayout></PrivateRoute>}></Route>
          <Route path="/demo" element={<DemoPage />}></Route>
          <Route path="/page" element={<PageArea />}></Route>
          <Route path="/" element={<SignIn />}></Route>
          <Route path="/signin" element={<SignIn />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/password-reset" element={<RecoverPassword />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/createpage" element={<PrivateRoute><PageLayout><CreatePage /></PageLayout></PrivateRoute>}></Route>
        </Routes>
    </Router>
  );
};

const PrivateRoute = ({ children }) => {
  const auth = localStorage.getItem('token');
  return auth ? children : <Navigate to="/signin" />;
}

export default AppRouter;
