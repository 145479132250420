import axios from 'axios';

const getUserAccounts = async (userId) => {
	return await axios.get('/v1/users/' + [userId] + '/accounts').then((res) => res);
};

const setCurrentAccount = async (payload) => {
	return await axios.post(`/v1/users/${payload.userId}/current_account`, payload).then((res) => res);
};

export { getUserAccounts, setCurrentAccount };
