import { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import './TargetedAreas.css';

const View = ({ data, actionBodyTemplate, first, rows, paginatorTemplate, onCustomPage }) => {
    return (
        <div>
            <div className='targeted-areas-header'>Targeted Areas</div>
            <DataTable value={data} responsiveLayout="scroll" className="targeted-areas"
                paginator paginatorTemplate={paginatorTemplate} first={first} rows={rows} onPage={onCustomPage}
                paginatorClassName="justify-content-end">
                <Column field='name' header='Name' className="text-[#179ab7]"></Column>
                <Column field='deleted' header='Deleted'></Column>
                <Column body={actionBodyTemplate} header=""></Column>
            </DataTable>
        </div>
    )
}

const TargetedAreas = () => {
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(3);
    const data = [
        { name: 'Name with some words', deleted: 'Month 01, 2019' },
        { name: 'Name with some words', deleted: 'Month 01, 2019' },
        { name: 'Name with some words', deleted: 'Month 01, 2019' },
        { name: 'Name with some words', deleted: 'Month 01, 2019' },
        { name: 'Name with some words', deleted: 'Month 01, 2019' },
        { name: 'Name with some words', deleted: 'Month 01, 2019' },
        { name: 'Name with some words', deleted: 'Month 01, 2019' },
        { name: 'Name with some words', deleted: 'Month 01, 2019' },
        { name: 'Name with some words', deleted: 'Month 01, 2019' },
    ]

    const actionBodyTemplate = () => {
        return (
            <span >
                <button>RESTORE</button>
            </span>
        )
    }

    const paginatorTemplate = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 3, value: 3 },
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </>
            );
        },
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            )
        }
    };

    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    }

    return <View {...{ data, actionBodyTemplate, first, rows, paginatorTemplate, onCustomPage }} />
}

export default TargetedAreas;