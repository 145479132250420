import Footer from "../layout/footer/Footer";
import { useForm } from "react-hook-form";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { register as signup } from "../../services/auth.service";
import './Signup.css';
import {useRef , useState} from 'react';
import {Toast} from 'primereact/toast';
import { useEffect } from "react";

const View = ({ register, handleSubmit, onSubmit, errors,toast,responseErrorMsg}) => {
    return (
        <div>
            <div className="flex">
                <div className="w-72 h-screen bg-white pt-8 pr-8 pb-8 pl-12">
                    <div>
                        <img src="images/artboard-9-copy-copy@2x.png" />
                    </div>
                </div>
                <div className="flex-1 login-signup-bg">
                    <div className="login-signup-container margin-top50">
                        <div className="login-heaer">Personalize Content <br></br> and Convert Visitors to Customers.</div>
                        <div className="signup-container">
                            <div className="signup-left">
                                <div className="signup-form input-indent">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-input">
                                            <input
                                                type="text"
                                                placeholder="Name"
                                                name="name"
                                                {...register('name', { required: true })}
                                                className= {(errors.name?.type === 'required') || (responseErrorMsg?.name?.length>0)  ? "border-2 border-rose-600": ""}                                                                                             
                                            />
                                            {(errors.name?.type === 'required' || responseErrorMsg?.name?.length>0) ? 
                                              (<i className="error-image tooltip"><span className="tooltiptext">
                                                {responseErrorMsg?.name?.length>0 ? responseErrorMsg?.name : "This field is required."}</span></i> ) :""}                                            
                                        </div>
                                        <div className="form-input">
                                            <input
                                                type="text"
                                                placeholder="E-mail"
                                                name="email"
                                                {...register('email', { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i })}
                                                className= {(errors.email?.type === 'required' || responseErrorMsg?.email?.length>0 || errors.email?.type === 'pattern') ? "border-2 border-rose-600" : ""}
                                            />
                                             {(errors.email?.type === 'required' || responseErrorMsg?.email?.length>0 || errors.email?.type === 'pattern') ? 
                                              (<i className="error-image tooltip"><span className="tooltiptext">
                                                {(responseErrorMsg?.email?.length>0) ? responseErrorMsg?.email : (errors.email?.type === 'pattern')?"The email must be a valid email address." : "This field is required."}</span></i> ) :""} 
                                        </div>
                                        <div className="form-input">
                                            <input
                                                type="password"
                                                placeholder="Password"
                                                name="password"
                                                {...register('password', { required: true })}
                                                className= {(errors.password?.type === 'required' || responseErrorMsg?.password?.length>0)? "border-2 border-rose-600" : ""}
                                            /> 
                                            {(errors.password?.type === 'required' || responseErrorMsg?.password?.length>0) ? 
                                              (<i className="error-image tooltip"><span className="tooltiptext">
                                                {responseErrorMsg?.password?.length>0 ? responseErrorMsg?.password : "This field is required."}</span></i> ) :""}                                           
                                        </div>
                                        <div className="form-input">
                                            <input
                                                type="password"
                                                placeholder="Confirm Password"
                                                name="password_confirmation"
                                                {...register('password_confirmation', { required: true })}
                                                className= {(errors.password_confirmation?.type === 'required' || responseErrorMsg?.password_confirmation?.length>0) ? "border-2 border-rose-600" : ""}
                                            /> 
                                             {(errors.password_confirmation?.type === 'required' || responseErrorMsg?.password_confirmation?.length>0) ? 
                                              (<i className="error-image tooltip"><span className="tooltiptext">
                                                {responseErrorMsg?.password_confirmation?.length>0 ? responseErrorMsg?.password_confirmation : "This field is required."}</span></i> ) :""}
                                        </div>
                                        <div className="form-checkbox signup-inline">
                                            <input type="checkbox" name="terms"
                                                {...register('terms', { required: true })}
                                                className= {(errors.terms?.type === 'required') ? "outline-2 outline outline-red-900" : ""}
                                            />
                                            <span className="sign-label text-red-600">I agree to the <a href="#" className="text-red-600"> terms and conditions </a> </span>                                            
                                        </div>
                                        <button type="submit" className="login-orange">LAUNCH GEOFLI</button>
                                        <div className="flex justify-center font-[18px] p-[5px_0px] uppercase">
                                            <NavLink to="/signin" className="text-[#ec920a]">Sign In</NavLink>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="signup-right">
                                <div className="signup-right-hader">Improve your website’s conversion, pages-per-session and time-on-site by customizing content based on your users geographic location, industry or persona. See how it works.</div>

                                <div className="signup-video">
                                    <iframe src="https://www.youtube.com/embed/UAnulZYlJ7U?feature=oembed" title="Geofli" height="100%" width="100%"></iframe>
                                    {/* <img src="images/triangle.png" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Footer />
            </div>
            <Toast ref={toast}></Toast>            
        </div>
        
    )
}

const Signup = () => {    
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const toast = useRef(null); 
    const [responseErrorMsg, setResponseErrorMsg] = useState([]);
    const location = useLocation();
  
    useEffect(() => {
        if (localStorage.getItem('token')) {
            navigate('/dashboard');
        }
    },[location])

    const onSubmit = (params) => {
        let payload = {
            name: params.name,
            email: params.email,
            password: params.password,
            password_confirmation: params.password_confirmation,
            website_url: params.website_url || "https://test.com"
        }
        signup(payload).then(response => {
            if(response?.data){
                localStorage.setItem('token',response?.data?.token);
                localStorage.setItem('account_id',response?.data?.account.id);  
                localStorage.setItem('user_id',response?.data?.user.id);   
                navigate('/dashboard');
            }            
        })
        .catch(exception => {           
            setResponseErrorMsg(exception.response.data.errors);

            if(!exception.response.data.hasOwnProperty('errors'))
                toast.current.show({severity: 'error', summary: 'Error', detail: exception?.response?.data.message, life: 3000});
        })
    }

   

    return <View {...{ register, handleSubmit, onSubmit, errors,toast,responseErrorMsg}} />
}

export default Signup;