import { useEffect } from "react";
import { useState } from "react";
import { getProxyURL } from "../../services/page.service";
import Modal from "../modal/Modal";

const View = ({ isOpen, xPath, setIsOpen, pageId, nodeName }) => {
    return (
        <div>
            <iframe id="myFrame" sandbox="allow-same-origin allow-presentation allow-scripts" width="100%" height="1000" className="border-0 p-5"></iframe>
            {isOpen &&
                <Modal {...{ xPath, setIsOpen, pageId, nodeName }} />
            }
        </div>
    )
}
//src={'http://localhost:1234/demo?url=' + pageURL}

const PageArea = ({ pageURL, pageId }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [xPath, setXPath] = useState("");
    const [nodeName, setNodeName] = useState("");

    useEffect(() => {
        onPageLoad(setIsOpen, setXPath, pageURL, setNodeName);
    }, []);

    return (<View {...{ isOpen, xPath, setIsOpen, pageId, nodeName }} />)
}

//-- on page load access iframe document and add event listeners
const onPageLoad = (setIsOpen, setXPath, pageURL, setNodeName) => {
    getProxyURL(pageURL).then(response => {   
        if(response?.data){  
            let iframe = document.getElementById("myFrame");
            iframe.srcdoc = response?.data;
        }            
    })
    .catch(exception => {        
        //if(!exception.response?.data.hasOwnProperty('errors'))
        //   toast.current.show({severity: 'error', summary: 'Error', detail: exception?.response?.data.message, life: 3000});          
    });

    setTimeout(() => {
        let iframe = document.getElementById("myFrame");
        let iframeDocument = iframe.contentWindow.document;
        //iframeDocument.body.style.padding = "1rem";
        iframeDocument.addEventListener("mouseover", onMouseOver);
        iframeDocument.addEventListener("mouseout", onMouseOut);
        iframeDocument.addEventListener("click", (e) => {
            setIsOpen(true);
            e = e || window.event;
            let target = e.target || e.srcElement;
            let xpath = getXPathForElement(target);
            setXPath(xpath);
            setNodeName(target?.nodeName);
        });
        }, 2000);
}

//-- on mouse over add outline on element
const onMouseOver = (e) => {
    e.target.style.outline = "thick solid #09bce3";
}

//-- on mouse out remove outline from element
const onMouseOut = (e) => {
    e.target.style.outline = null;
}

//-- get xpath of an element
const getXPathForElement = (element) => {
    const idx = (sib, name) => sib
        ? idx(sib.previousElementSibling, name || sib.localName) + (sib.localName == name)
        : 1;
    const segs = elm => !elm || elm.nodeType !== 1
        ? ['']
        : elm.id && document.getElementById(elm.id) === elm
            ? [`id("${elm.id}")`]
            : [...segs(elm.parentNode), `${elm.localName.toLowerCase()}[${idx(elm, name)}]`];
    return segs(element).join('/');
}

export default PageArea;