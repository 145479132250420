import './InviteUser.css';

const View = () => {
    return (
        <>
            <div className='userTabHeader'>Invite a user</div>
            <div className='userTabinput'>
                <input type="text" placeholder="Email" />
                <input type="text" placeholder="Role" />
                <textarea row="3" placeholder="Add a note..." ></textarea>
                <div className='price-txt'> <button>INVITE</button></div>
            </div>
        </>
    )
}

const InviteUser = () => {
    return <View />
}

export default InviteUser;