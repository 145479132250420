import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import './PageList.css';

const View = ({ pages, dynamicColumns, first, rows, paginatorTemplate, onCustomPage, header, headerTemplate, footerTemplate }) => {
    return (
        <div>
            <div className="bg-white listContainer">
                <DataTable value={pages} responsiveLayout="scroll" rowGroupMode="subheader" groupRowsBy="column6"
                    paginator first={first} rows={rows} onPage={onCustomPage}
                    paginatorClassName="justify-content-end" header={header} sortMode="multiple" rowGroupHeaderTemplate={headerTemplate}
                    rowGroupFooterTemplate={footerTemplate} className="pagelist">
                    {dynamicColumns}
                </DataTable>
            </div>
        </div>
    )
}

const PageList = ({ isHeader, isEdit, isFooter, pages, isRowGroup, columns }) => {
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const paginatorTemplate = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            )
        }
    };

    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    }

    const header = () => {
        return(
            <>
                {isHeader &&
                    <div className="table-header">
                    <div className='progress-bardiv'>
                        <div className='progress-head'>
                            Almost done!
                        </div>
                        <div className="progress blue">
                            <span className="progress-left">
                                <span className="progress-bar"></span>
                            </span>
                            <span className="progress-right">
                                <span className="progress-bar"></span>
                            </span>
                            <div className="progress-value">75%</div>
                        </div>
                        <div className='progress-info'>
                            Complete your page set-up by
                            editing your content
                        </div>
    
                        <div className='progress-edit'>
                            <button>Edit Content</button>
                        </div>
                    </div>
                </div>
                }
            </>
        )
    };



    const headerTemplate = (data) => {
        return (
            <React.Fragment>
                {isRowGroup && 
                    <table>
                        <tr>
                            <td><div className='table-thbg'>{isEdit && <span><img src="images/edit-3-copy-3.png"></img></span>} <label>{data.column6}</label></div></td>
                            <td>{total(data.column6, 'column2')}</td>
                            <td>{total(data.column6, 'column3')}</td>
                            <td>{total(data.column6, 'column4')}</td>
                            <td></td>
                        </tr>
                    </table>
                }
            </React.Fragment>
        );
    }

    const footerTemplate = (data) => {
        return (
            <React.Fragment>
                <td colSpan={5} >
                    {isFooter &&<button>+ GeoFli More Content</button>}
                </td>
            </React.Fragment>
        );
    }

    const actionHeaderTemplate = (col) => {
        return (
            <React.Fragment>
                {col.header}
                {col.icon && <img src="images/question.png" />}
            </React.Fragment>
        )
    }

    const dynamicColumns = columns.map((col, i) => {
        return (
            <Column key={col.field} field={col.field} header={() => actionHeaderTemplate(col)} sortable={col.sortable} className={col.link && 'link'} />
        );
    });

    const total = (groupBy, columnName) => {
        let total = 0;
        pages.map(item => {
            if (item.column6 === groupBy) {
                total += Number(item[columnName]);
            }
        })
        return total;
    }

    return <View {...{ pages, dynamicColumns, first, rows, paginatorTemplate, onCustomPage, header, headerTemplate, footerTemplate }} />
}

export default PageList;