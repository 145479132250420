import './Membership.css';

const View = () => {
    return (
        <div>
            <div className='general-lage-text'>Only<span> 12 days remaining</span>
                <br></br> of your free trial</div>
            <div className='general-small-text'>You’ve done a great job this month! Keep improving <br></br> your site by subscribing to the suggest plan:</div>
            <div className='general-box-sh'>
                <div className='pointer-img'>
                    <img src="images/pointer.png" />
                    <img src="images/starter.png" />
                </div>

                <div className='general-box-txt'>
                    <div>Up to 10,000 impressions</div>
                    <div>Unlimited GeoFli Pages</div>
                </div>
                <div className='price-txt'>
                    <button>$50 Monthly</button>
                    <span>or</span>
                    <button>$550 Yearly</button>
                </div>
                <div className='view-all-txt'>View all memberships</div>

            </div>
        </div>
    )
}

const Membership = () => {
    return <View />
}

export default Membership;