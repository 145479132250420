import React, { useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Chart } from 'primereact/chart';
import './AudienceSegment.css';

const View = ({ activeTab, setActiveTab, chartData, chartOptions, header1Template, header2Template }) => {
    return (
        <>
            <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                <TabPanel headerTemplate={header1Template}>
                    <Chart type="bar" data={chartData} options={chartOptions} />
                    <button className="border-dashed border-2 border-2xl border-cyan-500 text-cyan-500">
                        <img src="images/copy-4.png"></img>
                        Add Audience
                    </button>
                </TabPanel>
                <TabPanel headerTemplate={header2Template} headerClassName="left-arrow" disabled={true}>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                        architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                        voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.</p>
                </TabPanel>
            </TabView>
        </>
    )
}

const AudienceSegment = () => {
    const [activeTab, setActiveTab] = useState(0);
    const { chartOptions } = getLightTheme();
    const [chartData] = useState({
        labels: ['Southwest', 'New York', 'Pacefic NorthWest', 'La'],
        datasets: [
            {
                label: '',
                backgroundColor: [
                    'rgba(67,203,10,0.7)',
                    'rgba(67,203,10,0.6)',
                    'rgba(67,203,10,0.5)',
                    'rgba(67,203,10,0.4)'
                ],
                data: [80, 59, 47, 20]
            }
        ]
    });

    return (
        <View {...{ activeTab, setActiveTab, chartData, chartOptions, header1Template, header2Template }} />
    )
}

const header1Template = (options) => {
    return (
        <div onClick={options.onClick}>
            <span>Total Visit</span>
            <span>140.3K</span>
        </div>
    )
}

const header2Template = (options) => {
    return (
        <div onClick={options.onClick}>
            <span>Date Geoflied</span>
            <span>01/07</span>
            <span>2019</span>
        </div>
    )
}

const getLightTheme = () => {
    let chartOptions = {
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            legend: {
                labels: {
                    color: '#179ab7'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#179ab7'
                },
                grid: {
                    display: false,
                    drawBorder: false
                },
            },
            y: {
                display: false,
                grid: {
                    display: false,
                }
            }
        }
    };

    return {
        chartOptions
    }
};

export default AudienceSegment;