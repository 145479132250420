import { useState } from 'react';
import AudienceSegment from '../../components/audience-segment/AudienceSegment';
import PageList from '../../components/page-list/PageList';
import MapView from '../../components/map-view/MapView';
import { Calendar } from 'primereact/calendar';
import './DashboardPage.css';
import { NavLink } from 'react-router-dom';

const DashboardPage = () => {
    const [dateRange, setDateRange] = useState(null);
    const columns = [
        { field: 'column1', header: '', sortable: false, icon: false, link: false },
        { field: 'column2', header: 'Actualizations', sortable: true, icon: true, link: false },
        { field: 'column3', header: 'On-Page Clicks', sortable: true, icon: true, link: false },
        { field: 'column4', header: 'Sessions', sortable: true, icon: true, link: false },
        { field: 'column5', header: 'Date Geoflied', sortable: true, icon: true, link: false },
    ];
    const pages = [
        { column1: 'Southwest', column2: '9620', column3: '86', column4: '42302', column5: '01/03/2019', column6: 'A-Page Name with words and information' },
        { column1: 'New York', column2: '7280', column3: '30', column4: '12000', column5: '01/03/2019', column6: 'A-Page Name with words and information' },
        { column1: 'LA', column2: '3100', column3: '4', column4: '6000', column5: '01/03/2019', column6: 'A-Page Name with words and information' },
        { column1: 'CA', column2: '2000', column3: '2', column4: '2312', column5: '01/05/2019', column6: 'A-Page Name with words and information' },
        { column1: 'Southwest', column2: '9620', column3: '86', column4: '42302', column5: '01/03/2019', column6: 'B-Page Name with words and information' },
        { column1: 'New York', column2: '7280', column3: '30', column4: '12000', column5: '01/03/2019', column6: 'B-Page Name with words and information' },
        { column1: 'LA', column2: '3100', column3: '4', column4: '6000', column5: '01/03/2019', column6: 'B-Page Name with words and information' },
        { column1: 'Southwest', column2: '9620', column3: '86', column4: '42302', column5: '01/03/2019', column6: 'C-Page Name with words and information' },
        { column1: 'New York', column2: '7280', column3: '30', column4: '12000', column5: '01/03/2019', column6: 'C-Page Name with words and information' }
    ];
    return (
        <div className="">
            <div className="notifactiondiv">
                <div className="blube-img">
                    <img src="images/blube.png" />
                </div>
                <div className="notifaction-info">
                    Page set-up pending to complete.
                    <span>Just 2 steps to start Increase Time-On-Site</span>
                    <a href="#">  here</a>
                </div>
                <img src="images/close.svg" className="close-div" />
            </div>
            <div className="text-center">
                <h1 className="headertext">Pages Overview
                    <Calendar value={dateRange} onChange={(e) => setDateRange(e.value)} selectionMode="range" readOnlyInput />
                    <span className="p-dropdown-trigger-icon p-clickable pi pi-chevron-down overviewcolor"></span>
                </h1>
            </div>

            <div className='containermap'>
                <MapView />
                <AudienceSegment />
            </div>
            <div className="cardcontainer">
                <ul className="flex">
                    <li className="flex-1">
                        <div className="cardheader"> Time-On-Site</div>
                        <div className="cardtime">
                            140 secs
                        </div>
                        <div className="cardinfo"> 2% Increase</div>
                        <div className="cardinfoimg"> <img src="images/card-info.png" /></div>
                    </li>

                    <li className="flex-1">
                        <div className="cardheader"> Pages Per-Session</div>
                        <div className="cardtime">
                            4 pages
                        </div>
                        <div className="cardinfo"> 10% Increase</div>
                        <div className="cardinfoimg"> <img src="images/card-info.png" /></div>
                    </li>

                    <li className="flex-1">
                        <div className="cardheader"> Conversion Rate</div>
                        <div className="cardtime">
                            Great work!
                        </div>
                        <div className="cardinfo"> 22% Increase</div>
                        <div className="cardinfoimg"> <img src="images/card-info.png" /></div>
                    </li>

                    <li className="flex-1">
                        <div className="cardheader"> GeoFli Redirects</div>
                        <div className="cardtime">
                            102
                        </div>
                        <div className="cardinfo"> 10% Increase</div>
                        <div className="cardinfoimg"> <img src="images/card-info.png" /></div>
                    </li>
                </ul>
            </div>
            <div className="text-center">
                <h1 className="headertext">GEOFLI PAGES</h1>
            </div>
            <div className="large-addbtn">
                <button><NavLink to="/createpage">  + Add Page </NavLink></button>
            </div>
            <PageList
                isHeader={true}
                isEdit={true}
                isFooter={true}
                pages={pages}
                isRowGroup={true}
                columns={columns}
            />
        </div>
    )
}

export default DashboardPage;




