import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./SideBar.css"

const SideBar = ({ setVisibleTop }) => {
	const location = useLocation();
	const navigate = useNavigate();

	const logout = () =>{
		localStorage.clear();
		setTimeout(() => {
			navigate('/signin');
		}, 500);
	}

	return <div className="sidebar">
		<NavLink to="/dashboard">
			<img src="images/artboard-9-copy-copy@2x.png" />
		</NavLink>
		<ul className="mt-28">
			<li className="flex py-4">
				<NavLink to="/dashboard" className={({ isActive }) => isActive ? 'text-cyan-500 inline-flex' : 'inline-flex'} onClick={()=> setVisibleTop(false)}>
					<span><img src={location.pathname === '/dashboard' ? 'images/web-page-home-active.png' : 'images/web-page-home-1.png'} /></span>
					<span className="pl-4">DASHBOARD</span>
				</NavLink>
			</li>
			<li className="flex py-4">
				<NavLink to="/pages" className={({ isActive }) => isActive ? 'text-cyan-500 inline-flex' : 'inline-flex'} onClick={()=> setVisibleTop(false)}>
					<span><img src={location.pathname === '/pages' ? 'images/group-5-active.png' : 'images/group-5.png'} /></span>
					<span className="pl-4">PAGES</span>
				</NavLink>
			</li>
			<li className="flex py-4">
				<NavLink to="/audiences" className={({ isActive }) => isActive ? 'text-cyan-500 inline-flex' : 'inline-flex'} onClick={()=> setVisibleTop(false)}>
					<span><img src={location.pathname === '/audiences' ? 'images/audience-active.png' : 'images/audience.png'} /></span>
					<span className="pl-4">AUDIENCES</span>
				</NavLink>
			</li>
			<li className="flex py-4">
				<NavLink to="/content" className={({ isActive }) => isActive ? 'text-cyan-500 inline-flex' : 'inline-flex'} onClick={()=> setVisibleTop(false)}>
					<span><img src={location.pathname === '/content' ? 'images/group-6-active.png' : 'images/group-6.png'} /></span>
					<span className="pl-4">CONTENT</span>
				</NavLink>
			</li>
			<li className="flex py-4">
				<NavLink to="/analytics" className={({ isActive }) => isActive ? 'text-cyan-500 inline-flex' : 'inline-flex'} onClick={()=> setVisibleTop(false)}>
					<span><img src={location.pathname === '/analytics' ? 'images/profit-active.png' : 'images/profit.png'} /></span>
					<span className="pl-4">ANALYTICS</span>
				</NavLink>
			</li>
			<li className="flex py-4">
				<NavLink to="/account" className={({ isActive }) => isActive ? 'text-cyan-500 inline-flex' : 'inline-flex'} onClick={()=> setVisibleTop(false)}>
					<span><img src={location.pathname === '/account' ? 'images/group-7-active.png' : 'images/group-7.png'} /></span>
					<span className="pl-4">ACCOUNT</span>
				</NavLink>
			</li>
			<li className="flex py-4">
				<NavLink className={({ isActive }) => isActive ? 'inline-flex' : 'inline-flex'} onClick={()=> {logout();setVisibleTop(false)}}>
					<span><img src={location.pathname === '/logout' ? 'images/logout-active.png' : 'images/logout.png'} /></span>
					<span className="pl-4">LOG OUT</span>
				</NavLink>
			</li>
		</ul>

		<div className="support-link">
			<ul>
				<li> <span> <img src="images/chat.png" /></span> <a href="#"> SUPPORT</a></li>
			</ul>
		</div>

		<ul className="mt-24">
			<li className="flex py-4">
				<span><img src="images/inside.png" /></span>
				<span className="pl-4">
					<NavLink to="/minimize" className={({ isActive }) => isActive ? 'text-cyan-500' : ''}>MINIMIZE</NavLink>
				</span>
			</li>
		</ul>
	</div>;
};

export default SideBar;
