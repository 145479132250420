import './PagesPageBox.css';

const View = ({ item }) => {
    return (

        <div className="grid-view-card bg-white">
            <div className="card-header">
                <div className="grid-view-header">
                    <div className='grid-view-header-bg'>
                        <span className='grid-view-header-img'>
                            <img src="images/edit-3-copy-3.png"></img>
                        </span>
                        <div className='grid-view-header-info'>
                            <span>{item.name}</span>
                            <label>{item.domain}</label>
                        </div>
                    </div>
                    <div className='grid-view-price'>{item.value}</div>
                </div>
            </div>
            {item.type === '1'
                ? <div className="card-body">
                    <img src="images/page-map.png" className='img100' />
                    <div className='grid-view-buton'>
                        <a>VIEW ALL AUDIENCES (4 MORE)</a>
                        <button>+ ADD AUDIENCE </button>
                    </div>
                </div>
                : <div className="card-body">
                    <div>
                        <img className='w-[100%]' src="images/tye.png" />
                    </div>
                    <div className='text-center'>
                        <button className='text-[#70c60f] font-semibold border-2 border-[#70c60f] border-solid p-[10px] w-[80%] m-[18px]'>CONTINUE</button>
                    </div>
                </div>

            }
            {item.type === '1' &&
                <div className="grid-view-footer bg-white">
                    <button><span>+</span> ADD CONTENT</button>
                    <button><label>||</label>PAUSE PAGE </button>
                </div>
            }
        </div>

    )
}

const PagesPageBox = ({ item }) => {
    return <View {...{ item }} />
}

export default PagesPageBox;