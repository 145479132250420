import { useState } from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import SideBar from '../sideBar/SideBar';

const PageLayout = ({ children }) => {
	const [visibleTop, setVisibleTop] = useState(false);
	return (
		<div>
			<div className="flex"> 
			<div className="w-72">
				<SideBar setVisibleTop={setVisibleTop} />
			</div>
			<div className="flex-1 bg-gray-100">
				<Header 
					type={1}
					visibleTop={visibleTop}
					setVisibleTop={setVisibleTop} />
				<main className='p-4'>{children}</main>
			</div>
			</div>
			<div>
				<Footer />
			</div>
		</div>
	);
};

export default PageLayout;
