import { useState } from 'react';
import BillingSubscriptionStart from '../billing-subscription-start/BillingSubscriptionStart';
import './BillingNotSubscribed.css';

const View = ({ isOpen, setIsOpen, subscriptionType, setSubscriptionType }) => {
    return (
        <div>
            <div className='general-lage-text text-center'>
                Only <span>12 days remaining</span>  of your free trial,<br />
                keep improving your site by subscribing below:
            </div>
            <div className='billing-card-view'>
                <div className='billing-card'>
                    <div className='billing-card-img'>
                        <img src="images/intermediate-up.png" />
                        <img src="images/intermediate-down.png" />
                    </div>
                    <div className='billing-card-info'>
                        Up to 50,000 impressions<br />
                        Unlimited GeoFli Pages
                    </div>
                    <div className='billing-card-btn'>
                        <button onClick={() => {setIsOpen(true);setSubscriptionType(1)}}>$150 Monthly</button>
                        <span>Or</span>
                        <button onClick={() => {setIsOpen(true);setSubscriptionType(1)}}>$1,500 Yearly</button>
                    </div>
                </div>
                <div className='billing-card billing-card-starter'>
                    <div className='billing-card-img'>
                        <img src="images/pro-up.png" />
                        <img src="images/starter-down.png" />
                    </div>
                    <div className='billing-card-info'>
                        Up to 10,000 impressions<br />
                        Unlimited GeoFli Pages
                    </div>
                    <div className='billing-card-btn'>
                        <button onClick={() => {setIsOpen(true);setSubscriptionType(0)}}>$50 Monthly</button>
                        <span>Or</span>
                        <button onClick={() => {setIsOpen(true);setSubscriptionType(0)}}>$550 Yearly</button>
                    </div>
                </div>
                <div className='billing-card'>
                    <div className='billing-card-img'>
                        <img src="images/pro-up.png" />
                        <img src="images/pro-down.png" />
                    </div>
                    <div className='billing-card-info'>
                        Up to 100,000 impressions<br />
                        Unlimited GeoFli Pages
                    </div>
                    <div className='billing-card-btn'>
                        <button onClick={() => {setIsOpen(true);setSubscriptionType(2)}}>$200 Monthly</button>
                        <span>Or</span>
                        <button onClick={() => {setIsOpen(true);setSubscriptionType(2)}}>$2,000 Yearly</button>
                    </div>
                </div>
            </div>
            <BillingSubscriptionStart isOpen={isOpen} setIsOpen={setIsOpen} subscriptionType={subscriptionType} />
        </div>
    )
}

const BillingNotSubscribed = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [subscriptionType, setSubscriptionType] = useState(0);
    return <View {...{ isOpen, setIsOpen, subscriptionType, setSubscriptionType }} />
}

export default BillingNotSubscribed;